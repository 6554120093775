@import "variable";

body {
  .accountSettingsPage {
    padding-top: 56px;
    margin: 0px 80px;
    .accountSettingsWrapper {
      .accountSettingsTitle {
        margin: 24px 0px;
        color: $primaryBlue;
      }
      .accountSettingsBlk {
        background: $white;
        box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
        border-radius: 6px;
        margin-bottom: 50px;
        .accountSettingsTabWrapper {
          height: 100%;
          border-right: 1px solid $gray10;
          .accountSettingsTabs {
            ul {
              padding: 18px 16px;
              li {
                padding: 16px 20px;
                color: $gray50;
                font-weight: $mediumFont;
                font-size: $font14;
                line-height: $lineHeight21;
                cursor: pointer;
                &.active,
                &:hover {
                  background: $background10;
                  border-radius: 6px;
                  color: $gray90;
                }
              }
            }
          }
        }
        .accountSettingsTabContentWrapper {
          height: 100%;
          .accountSettingsTabContent {
            padding: 24px;
            .profileTab {
              padding-bottom: 150px;
              .profileImgWrapper {
                position: relative;
                margin-bottom: 34px;
                & img {
                  width: 160px;
                  height: 160px;
                  object-fit: cover;
                  border-radius: 6px;
                }
                .inputFile {
                  position: relative;
                  width: 80px;
                  height: 32px;
                  left: 26px;
                  top: -73px;
                  opacity: 0;
                  cursor: pointer;
                }
                .uploadBtn {
                  border-radius: 8px;
                  padding: 6px 16px;
                  position: absolute;
                  left: 184px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
              .profileTabInput {
                & :global(.inputGroup) {
                  margin-bottom: 18px;
                }
              }
              .btnWrapper {
                margin-top: 34px;
                .saveBtn {
                  padding: 14px 54px;
                }
              }
            }
            .passwordTab {
              padding-bottom: 150px;
              .passwordTabInput {
                & :global(.inputGroup) {
                  margin-bottom: 26px;
                }
                position: relative;
                .showPassword {
                  position: absolute;
                  top: 39px;
                  right: 14px;
                  z-index: 99;
                  cursor: pointer;
                  text-transform: uppercase;
                }
              }
              .passwordBtnWrapper {
                margin-top: 40px;
                .saveBtn {
                  padding: 14px 74px;
                }
              }
            }
            .addressTab {
              .addNewAddress {
                .addNewAddressBtn {
                  padding: 6px 16px;
                  float: right;
                  margin-bottom: 16px;
                  & img {
                    vertical-align: middle;
                  }
                }
              }
              .addressCard {
                background: $white;
                border: 1px solid $gray10;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 16px;
                .addressCardSelect {
                  position: relative;
                  .addressCardRadioBtn {
                    position: absolute;
                    left: 0;
                    top: -8px;
                  }
                  .addressDetailsWrapper {
                    margin-left: 40px;
                    .addressName {
                      color: $primaryBlue;
                      font-weight: $mediumFont;
                      margin-bottom: 2px;
                    }
                    .address {
                      color: $primaryBlue;
                      line-height: $lineHeight21;
                      font-weight: $regularFont;
                      margin-bottom: 34px;
                    }
                  }
                }
                .addressCardFooter {
                  padding-bottom: 40px;
                  .btnWrapper {
                    float: right;
                    & button {
                      padding: 4px 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
