@import "variable";
body {
    .addOrganisationPage{
        padding-top: 56px;
    margin: 0px 80px;
        .addOrganisationWrapper{
            .backBtnWrapper{
                padding: 32px 0px;
            }
            .addOrganisationFormWrapper{
                padding: 40px;
                margin-bottom: 60px;
                background: $white;
                box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
                border-radius: 6px;
                .addOrganisationFormHeader{
                    color: $gray100;
                    line-height: $lineHeight36;
                    padding-bottom: 32px;
                }
                .addOrgFormLeftBlk{
                    padding-right: 24px;
                    .addOrgFormInnerHeader{
                        color: $gray50;
                        font-weight: $mediumFont;
                        padding-bottom: 16px;
                    }
                }
                .addOrgFormRightBlk{
                    padding-left: 24px;
                    .addOrgFormInnerHeader{
                        color: $gray50;
                        font-weight: $mediumFont;
                        padding-bottom: 16px;
                    }
                }
                .addOrgFormSubmitButtonWrapper{
                    padding-top: 32px;
                    display: flex;
                    justify-content: flex-end;
                    .submitButton{
                        display: flex;
                        padding: 13px 45px;
                    }
                }
            }
        }
    }
    .addOrgStatusBlk{
        background: linear-gradient(180deg, #F7FBF9 0%, #ECF3F8 100%);
        background-repeat: no-repeat;
        padding: 60px 0;
        .addOrgStatus{
            background-color: #fff;
            margin-top: 50px;
            border-radius: 4px;
            text-align: center;
            .statusImage{
                background-color: $status_green;
                padding: 50px 0 5px 0;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
            }
            .statusBody{
                .statusTitle{
                    padding: 24px 0 8px 0;
                    color: $gray100;
                    line-height: $lineHeight36;
                }
                .statusSubtext{
                    color: $gray50;
                    font-weight: $mediumFont;
                }
                .statusButtonWrapper{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 24px 0;
                    gap: 20px;
                    .actionBtn{
                        padding: 14px 18px;
                    }
                }
            }
        }
    }
}