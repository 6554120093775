@import "variable";
body {
  .overviewPage {
    padding: 114px 0px 0px 240px;
    .overviewWrapper {
      .overviewHeaderWrapper {
        .overviewPageTitleWrapper {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
          .overviewPageTitle {
            color: $primaryBlue;
          }
        }
      }
      .organizationDetailsCardWrapper {
        padding: 24px;
        .organizationDetailsCard {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;
          .organizationDetailsTitle {
            color: $gray50;
            padding: 24px;
            padding-bottom: 20px;
          }
          .cardBody {
            padding: 24px;
            padding-top: 0;
            .orgDetailsLeftBlk {
              & img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                margin-bottom: 16px;
              }
              .orgName {
                margin-bottom: 12px;
                color: $primaryBlue;
              }
              .orgAddress {
                color: $gray80;
                font-weight: $regularFont;
                line-height: $lineHeight21;
              }
            }
            .orgDetailsRightBlk {
              .modal {
                display: none; /* Hidden by default */
                position: fixed; /* Stay in place */
                z-index: 1; /* Sit on top */
                left: 0;
                top: 0;
                width: 100%; /* Full width */
                height: 100%; /* Full height */
                overflow: hidden; /* Enable scroll if needed */
                background-color: rgb(0,0,0); /* Fallback color */
                background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
              }
               /* Modal Content */
          .modalContent {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border: 1px solid #888;
            width: 30%;
            margin-top: 300px;
          }
           /* The Close Button */
           .close {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
          }
          
          .close:hover,
          .close:focus {
            color: #000;
            text-decoration: none;
            cursor: pointer;
          }
              .orgDetailsRightBlkItem {
                margin-bottom: 16px;
                .orgDetailsInnerLeftBlk {
                  .orgDetailsInnerLeftText {
                    color: $gray50;
                    line-height: $lineHeight21;
                    font-weight: $regularFont;
                  }
                }
                .orgDetailsInnerRightBlk {
                  .orgDetailsInnerRightText {
                    color: $primaryBlue;
                    font-weight: $regularFont;
                  }
                  .orgDetailsInnerRightLinkText {
                    color: $blue;
                    font-weight: $regularFont;
                  }
                }
              }
            }
          }
          .cardFooter {
            border-top: 1px solid $gray10;
            .orgDetailsTotalBlk {
              padding: 24px;
              border-right: 1px solid $gray10;
              position: relative;
              &.noBorder {
                border: none;
              }
              & img {
                width: 48px;
                height: 48px;
                object-fit: cover;
                position: absolute;
                left: 24px;
                top: 24px;
              }
              .orgDetailsTotalWrapper {
                display: inline-block;
                margin-left: 72px;
                .orgTotalText {
                  margin-bottom: 4px;
                  color: $gray50;
                  font-weight: $regularFont;
                  line-height: $lineHeight21;
                }
                .orgTotalCount {
                  color: $primaryBlue;
                }
              }
            }
          }
        }
      }
      .devicesCardWrapper {
        padding: 24px;
        padding-top: 0px;
      }
    }
  }
}
