@import "variable";

body {
  .activityLogPage {
    padding-top: 56px;
    margin: 0px 80px;
    .activityLogWrapper {
      .backBtnWrapper {
        padding: 16px 0px;
      }
      .activityLogHeaderWrapper {
        .activityLogPageTitleWrapper {
          padding: 16px 0px;
          border-bottom: 1px solid $gray10;
          .activityLogPageTitle {
            color: $primaryBlue;
          }
        }
      }

      .activityLogListBlk {
        .activityLogListWrapper {
          .activityLogFilterBlk {
            padding: 14px 0px;
            .activityLogSearchBlk {
              float: left;
              width: 55%;
              .activityLogSearchInput {
                position: relative;
                width: 65%;
                float: right;
                & input {
                  background: $white;
                  border: none;
                  height: 32px;
                  padding: 14px 8px;
                  font-size: $font12;
                  border-radius: 4px;
                }
                & img {
                  object-fit: cover;
                  position: absolute;
                  right: 6px;
                  top: 7px;
                }
              }
            }
            .teamMemberSelect {
              float: right;
              width: 40%;
              & :global(.inputGroup) {
                margin-bottom: 0;
              }
              & :global(.reactSelect > div) {
                height: 32px;
                min-height: 32px;
              }
              & :global(.reactSelect > div > div) {
                height: 32px;
                min-height: 32px;
              }
            }
          }
          .activityLogListOuterWrapper {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;
            margin-bottom: 50px;
            .activityLogTableTitleRow {
              padding: 16px;
              border-bottom: 1px solid $gray10;
              .activityLogTableTitle {
                color: $gray50;
                line-height: $lineHeight21;
                font-weight: $regularFont;
              }
            }
            .activityLogTableContent {
              .activityLogListItemWrapper {
                .activityLogListItem {
                  padding: 18px 16px;
                  border-bottom: 1px solid $gray10;
                  &:last-child {
                    border-bottom: none;
                  }
                  .activityLogListItemTextWrapper {
                    position: relative;
                    padding-bottom: 6px;
                    & :global(.avatar) {
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                    .activityLogListItemText {
                      color: $gray50;
                      margin-left: 44px;
                      padding-top: 4px;
                      & span {
                        color: $primaryBlue;
                        font-weight: $mediumFont;
                        &:first-child {
                          margin-right: 8px;
                        }
                        &:last-child {
                          margin-left: 8px;
                        }
                      }
                    }
                  }
                  .activityLogTime {
                    color: $gray50;
                    padding-top: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
