@import "../variable";
body {
  .quoteNavigationWrapper {
    margin-top: 60px;
    padding: 38px;
    display: flex;
    justify-content: center;
    position: relative;
    .backBtnWrapper {
      position: absolute;
      left: 0;
      display: flex;
      align-self: center;
      & span {
        color: $gray60;
      }
    }
    .quoteNavigationList {
      padding: 0;
      .quoteNavigationListItem {
        font-weight: $mediumFont;
        color: $primaryBlue;
        display: inline-flex;
        padding: 0;
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          content: "";
          display: inline-block;
          height: 1px;
          width: 50px;
          margin: 0px 8px;
          background: $gray10;
          position: relative;
          top: 1px;
        }
        &.active {
          & .cartNavigationListNumber {
            color: $white;
            background: $color_success_text;
            display: inline-block;
          }
          &::after {
            background: $color_success_text;
          }
        }
        & .quoteNavigationListNumber {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          padding: 3px 0px;
          border-radius: 50px;
          background: $white;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
  .quotationDetailWrapper {
    background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
    background-repeat: no-repeat;
    padding-bottom: 60px;
    .quoteDetailBlk {
      background-color: $white;
      box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
      border-radius: 6px;
      .vingBox {
        padding: 136px 72px 250px 72px;
      }
      .quoteTitle {
        padding-top: 48px;
        color: $gray100;
        line-height: $lineHeight36;
      }
      .subTitle {
        padding: 24px 0 12px 0;
        color: $gray70;
      }
      .quantitySection {
        display: flex;
        align-items: center;
        .decrementButton {
          padding: 12px;
          border: 1px solid $gray10;
          border-radius: 8px 0 0 8px;
          &:active {
            border: 1px solid $blue;
            background: $background1;
          }
        }
        & input[type="number"] {
          height: 53px;
          color: $gray100;
          text-align: center;
          border: none;
          border-bottom: 1px solid $gray10;
          border-top: 1px solid $gray10;
          &:focus {
            background: $background1;
          }
        }
        .incrementButton {
          padding: 12px;
          border: 1px solid $gray10;
          border-radius: 0 8px 8px 0;
          &:active {
            border: 1px solid $blue;
            background: $background1;
          }
        }
      }
      .subscriptionPeriodBlk {
        .subNumber {
          .subButton {
            position: relative;
            padding-right: 24px;
            .discountBanner {
              position: absolute;
              top: 4px;
              left: -8px;
              padding: 6px;
              font-size: 0;
              width: 100px;
              background-image: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/discountBanner.svg);
              background-repeat: no-repeat;
              background-size: contain;
              text-align: center;
              font-size: $font10;
              font-weight: $boldFont;
              color: $white;
              line-height: $lineHeight12;
            }
          }
        }
      }
      .organisationSection {
        display: flex;
        align-items: center;
        .selectDropdown {
          width: 436px;
        }
      }
      .requestButton {
        margin: 40px 0 40px 230px;
        padding: 14px 48px;
      }
    }
  }
  .quotationStatusBlk {
    background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
    background-repeat: no-repeat;
    padding: 60px 0;
    .quotationStatus {
      background-color: #fff;
      margin-top: 50px;
      border-radius: 4px;
      text-align: center;
      .statusImage {
        background-color: $status_green;
        padding: 50px 0 5px 0;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .statusBody {
        .statusTitle {
          padding: 24px 0 8px 0;
          color: $gray100;
          line-height: $lineHeight36;
        }
        .statusSubtext {
          color: $gray50;
          font-weight: $mediumFont;
        }
        .statusButtonWrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 24px 0;
          gap: 20px;
          .actionBtn {
            padding: 14px 18px;
          }
        }
      }
    }
  }
}
