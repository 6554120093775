@import "variable";

body {
  .homePage {
    padding: 56px 0px 0px 240px;
    .homeWrapper {
      max-width: 1280px;
      .homeHeaderWrapper {
        .homePageTitleWrapper {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
          .homePageTitle {
            color: $primaryBlue;
          }
        }
      }
      .homeDevicesWrapper {
        padding: 24px;
      }
    }
  }
}
