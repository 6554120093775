@import "variable";
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .cartPage {
      .cartNavigationWrapper {
        padding: 36px 0px !important;
        .cartNavigationList {
          .cartNavigationListItem {
            font-size: $font10 !important;
            &::after {
              width: 18px !important;
            }
            & .cartNavigationListNumber {
              font-size: $font10 !important;
            }
          }
        }
      }
      .cartWrapper {
        margin: 0 !important;
        padding: 0px 16px !important;
        .orderSummaryWrapper {
          .orderSummaryCard {
            .cardHeader {
              padding: 18px 16px !important;
            }
            .cardBody {
              padding: 18px 16px !important;
              .orderSummaryDiscountWrapper {
                .discountApplyBtn {
                  padding: 14px 10px !important;
                }
              }
            }
            .cardFooter {
              .orderSummaryShippingAddressWrapper {
                padding: 18px 16px !important;
              }
            }
          }
        }
      }
      .orderStatusWrapper {
        .orderStatus {
          width: 100% !important;
        }
      }
    }
  }
}
body {
  overflow-x: hidden;
  .cartPage {
    background: $background5;
    padding: 36px 0 100px 0;

    .cartNavigationWrapper {
      padding: 36px 16px;
      padding-top: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      .cartNavigationList {
        padding: 0;
        .cartNavigationListItem {
          font-weight: $mediumFont;
          color: $primaryBlue;
          display: inline-flex;
          padding: 0;
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            display: inline-block;
            height: 1px;
            width: 50px;
            margin: 0px 8px;
            background: $gray10;
            position: relative;
            top: 1px;
          }
          &.active {
            & .cartNavigationListNumber {
              color: $white;
              background: $color_success_text;
              display: inline-block;
            }
            &::after {
              background: $color_success_text;
            }
          }
          & .cartNavigationListNumber {
            width: 28px;
            height: 28px;
            margin-right: 8px;
            padding: 3px 0px;
            border-radius: 50px;
            background: $white;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }

    .cartWrapper {
      background: $white;
      border-radius: 16px;
      //margin: 0 100px;
      padding: 24px ;
      .orderSummaryWrapper {
        .orderSummaryCard {
          background: $background4;
          border-radius: 12px;
          //margin: 24px 0px;
          .cardHeader {
            padding: 24px 32px;
            padding-bottom: 16px;
            .orderSummaryHeading {
              color: $gray90;
              display: inline-block;
            }
            .editBtn {
              padding: 4px 20px;
              float: right;
              position: relative;
              top: -2px;
            }
          }
          .cardBody {
            padding: 24px 32px;
            padding-top: 0;
            .orderSummaryBlk {
              background: $white;
              border: 1px solid $background5;
              border-radius: 8px;
              padding: 18px 28px;
              margin-bottom: 16px;
              & img {
                height: 60px;
                object-fit: cover;
              }
              .orderSummaryBlkDetails {
                .orderSummaryQuantity {
                  color: $gray50;
                  line-height: $lineHeight21;
                  font-weight: $regularFont;
                  margin: 6px 0px 8px 0px;
                  word-break: break-word;
                }
                .orderSummaryQuantityCount {
                  color: $primaryBlue;
                  line-height: $lineHeight21;
                  word-break: break-word;
                  font-size: $font14;
                  & span {
                    font-weight: $regularFont;
                    font-size: $font12;
                  }
                }
                .orderSummarySubscription {
                  color: $gray50;
                  line-height: $lineHeight21;
                  font-weight: $regularFont;
                  margin: 6px 0px 8px 0px;
                  word-break: break-word;
                }
                .orderSummarySubscriptionPeriod {
                  color: $primaryBlue;
                  line-height: $lineHeight21;
                  word-break: break-word;
                  font-size: $font14;
                  & span {
                    font-weight: $regularFont;
                    font-size: $font12;
                  }
                }
              }
            }
            .orderSummaryDiscountWrapper {
              .orderSummaryDiscountInput {
                & input {
                  background: $white;
                  &:focus {
                    background: $background1;
                  }
                }
              }
              .discountApplyBtn {
                height: 46px;
                margin-top: 24px;
                line-height: $lineHeight18;
              }
              .couponSuccessWrapper{
                position: relative;
                padding-bottom: 20px;

                .successText{
                  color:$color_success_text;
                  padding-bottom: 3px;
                }

                .couponSuccessTag{
                  img{
                  display: block;
                  }
                  padding: 12px;
                  background-color: $white;
                  border-radius: 8px;
                  border: 1px dashed $gray10;

                  .couponCode{
                    color: $gray70;
                    text-transform:uppercase;
                  }


                }

              }
            }
            .orderSummaryListWrapper {
              .orderSummaryListOuterBlk {
                border-bottom: 1px solid $background5;
                .orderSummaryListInnerlk {
                  margin-bottom: 12px;
                  &:last-child {
                    margin-bottom: 18px;
                  }
                  .orderSummaryDescription {
                    & p {
                      color: $gray50;
                      font-weight: $regularFont;
                      line-height: $lineHeight21;
                    }
                  }
                  .orderSummaryPrice {
                    & p {
                      text-align: right;
                      color: $primaryBlue;
                      font-weight: $mediumFont;
                      line-height: $lineHeight21;
                      .discountLabel {
                        text-align: center;
                        margin-right: 8px;
                        border-radius: 3px;
                        padding: 1px 5px;
                        background: $status_green;
                        color: $color_success_text;
                      }
                    }
                  }
                }
              }
              .orderSummaryTotalBlk {
                margin-top: 20px;

                .orderSummaryTotalText {
                  & p {
                    font-weight: $mediumFont;
                  }
                }
                .orderSummaryTotalPrice {
                  & p {
                    color: $primaryBlue;
                    text-align: right;
                  }
                }
              }
            }
          }
          .cardFooter {
            border-top: 1px solid $background5;
            .orderSummaryShippingAddressWrapper {
              padding: 24px 32px;
              .shippingAddressHeadingWrapper {
                margin-bottom: 20px;
                .shippingAddressHeading {
                  color: $gray60;
                  display: inline-block;
                }
                .editBtn {
                  padding: 4px 20px;
                  float: right;
                  position: relative;
                  top: -4px;
                }
              }
              .shippingAddressDetailsItemWrapper {
                .shippingAddressDetailsItem {
                  margin-bottom: 16px;
                  &:last-child {
                    margin-bottom: 6px;
                  }
                  .shippingDetailsLeft {
                    .shippingDetailsLeftText {
                      color: $gray50;
                      line-height: $lineHeight21;
                      font-weight: $regularFont;
                    }
                  }
                  .shippingDetailsRight {
                    .shippingDetailsRightText {
                      color: $primaryBlue;
                      line-height: $lineHeight21;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .orderStatusWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .orderStatusBlk {
      margin: 40px 0px;
      .orderStatus {
        width: 400px;
        height: 100%;
        background: $white;
        border-radius: 4px;
        &.orderConfirm {
          .statusHeader {
            background: $status_green;
          }
        }
        &.paymentFailed {
          .statusHeader {
            background: $status_red;
          }
        }
        &.connectionError {
          .statusHeader {
            background: $status_blue;
          }
        }
        .statusHeader {
          text-align: center;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          position: relative;
          height: 170px;
          & img {
            object-fit: cover;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
          & .connectionErrorImg {
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .statusBody {
          padding: 24px 16px;
          text-align: center;
          .statusTitle {
            color: $primaryBlue;
            line-height: $lineHeight36;
          }
          .statusSubtext {
            padding: 0px 20px;
            color: $gray50;
            margin-bottom: 26px;
            & span {
              color: $primaryBlue;
              font-weight: $mediumFont;
            }
          }
          .statusActionWrapper {
            text-align: center;
            & button {
              margin: 0 auto;
            }
            .actionBtn {
              padding: 14px 45px;
              display: block;
              margin-bottom: 12px;
            }
            .backBtn {
              padding: 14px 45px;
            }
          }
        }
      }
    }
  }
  // Arun
}
