@import "variable";
body {
  .quotationsPage {
    padding: 114px 0px 0px 240px;
    .quotationsListWrapper {
      .quotationHeading {
        padding: 16px 24px;
        border-bottom: 1px solid $gray10;
        .quotationTitle {
          color: $primaryBlue;
          .quotationTitleNum {
            color: $gray90;
            font-size: $font12;
          }
        }
      }
      .quotationsListStatus {
        .quotationsListFilter {
          display: flex;
          padding: 0;
          margin: 0 24px;
          border-bottom: 1px solid $gray10;
          .filterItem {
            width: auto;
            padding: 16px;
            font-weight: $mediumFont;
            text-transform: capitalize;
            cursor: pointer;
            border-bottom: 2px solid $white;
          }
          & :global(.Mui-selected) {
            border-bottom: 2px solid $blue;
          }
          & :global(.MuiTabs-indicator) {
            display: none;
          }
        }
      }
      .quotationSearchSection {
        padding: 16px 24px;
        .quotationSearchBlk {
          float: right;
          .quotationSearchInput {
            position: relative;
            width: 164px;

            & input {
              background: $white;
              border: none;
              height: 32px;
              padding: 14px 8px;
              font-size: $font12;
              border-radius: 4px;
            }
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }
        .quotationSortInput {
          width: 120px;
          float: right;
          margin-left: 16px;
          & :global(.inputGroup) {
            margin-bottom: 0;
          }
          // & :global(.reactSelect > div) {
          //   height: 32px;
          //   min-height: 32px;
          // }
          // & :global(.css-zv9he5-IndicatorsContainer){
          //   height: 31px;
          // }
          // & :global(.css-8u5oqz-ValueContainer){
          //   height: 31px;
          // }
          // & :global(.css-zr4xlc-placeholder){
          //   color: $gray100;
          // }
        }
      }
      .quotationsList {
        padding: 0px 24px 24px;
        .quotationsListInnerBlk {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;

          .quotationsListTableTitle {
            padding: 16px;
            border-bottom: 1px solid $gray10;
            .quotationsTableTitleTextWrapper {
              position: relative;
              display: inline-block;
              .quotationsTableTitleText {
                line-height: $lineHeight21;
                color: $gray50;
                font-weight: $regularFont;
                display: inline-block;
              }
              // & img {
              //   position: absolute;
              //   right: -16px;
              //   top: 4px;
              //   cursor: pointer;
              //   padding: 4px;
              // }
              .sortUp {
                height: 12px;
                width: 12px;
                position: relative;
                //top: 3px;
                cursor: pointer;
                padding-left: 4px;
              }
              .sortDown {
                height: 12px;
                width: 12px;
                position: relative;
                top: 1px;
                margin-left: -2px;
                cursor: pointer;
                padding-left: 4px;
              }
            }
          }
          .quotationsListTableContent {
            .quotationListItem {
              border-bottom: 1px solid $gray10;
              display: block;
              opacity: 0.9;
              transition: all 0.6s ease-in-out;
              &:hover {
                opacity: 1;
                box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
              }
              .quotationsListTableRow {
                padding: 12px 16px;
                height: 68px;
                .quotationContentWrapper {
                  position: relative;
                  .quotationImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 43px;
                    height: 43px;
                    object-fit: cover;
                  }
                  .quotationStatus {
                    position: absolute;
                    left: 18px;
                    top: 20px;

                    & img {
                      width: 25px;
                      object-fit: cover;
                    }
                  }
                  .quoteNum {
                    font-weight: $mediumFont;
                    color: $primaryBlue;
                    display: inline-block;
                    margin-left: 60px;
                    word-break: break-word;
                    margin-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 10px;
                  }
                  .requestedOn {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .unitsCount {
                    color: $primaryBlue;
                    font-weight: $mediumFont;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .subscriptionPeriod {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                    & span {
                      font-weight: $mediumFont;
                    }
                  }
                  .priceStatus {
                    border-radius: 4px;
                    font-weight: $mediumFont;
                    padding: 0px 6px;
                    display: inline-block;
                    margin-top: 8px;
                    &.success {
                      color: $color_success_text;
                      background: $status_green;
                    }
                    &.info {
                      color: $powder;
                      background: $status_blue;
                    }
                  }
                }
              }
            }
            .quotationListItemBlock {
              border-bottom: 1px solid $gray10;
              display: block;
              opacity: 0.7;
              transition: all 0.6s ease-in-out;
              cursor: not-allowed;
              &:hover {
                opacity: 0.7;
                box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
                cursor: not-allowed;
              }
              .quotationsListTableRow {
                padding: 12px 16px;
                height: 68px;
                .quotationContentWrapper {
                  position: relative;
                  .quotationImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 43px;
                    height: 43px;
                    object-fit: cover;
                  }
                  .quotationStatus {
                    position: absolute;
                    left: 18px;
                    top: 20px;

                    & img {
                      width: 25px;
                      object-fit: cover;
                    }
                  }
                  .quoteNum {
                    font-weight: $mediumFont;
                    color: $primaryBlue;
                    display: inline-block;
                    margin-left: 60px;
                    word-break: break-word;
                    margin-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 10px;
                  }
                  .requestedOn {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .unitsCount {
                    color: $primaryBlue;
                    font-weight: $mediumFont;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .subscriptionPeriod {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                    & span {
                      font-weight: $mediumFont;
                    }
                  }
                  .priceStatus {
                    border-radius: 4px;
                    font-weight: $mediumFont;
                    padding: 0px 6px;
                    display: inline-block;
                    margin-top: 8px;
                    &.success {
                      color: $color_success_text;
                      background: $status_green;
                    }
                    &.info {
                      color: $powder;
                      background: $status_blue;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .quotationOrderPage {
    padding: 114px 0px 0px 240px;
    .quotationOrderWrapper {
      .quoteOrderWrapper {
        .backBtnWrapper {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
        }
        .quoteOrderFormWrapper {
          .quoteOrderFormBlk {
            margin-bottom: 32px;
            .quoteFormLastUpdated {
              line-height: $lineHeight21;
              font-weight: $regularFont;
              color: $gray2;
              text-align: right;
              padding: 16px 0px;
            }
            .quoteOrderForm {
              background: $white;
              box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
              border-radius: 6px;
              padding: 24px;
              clear: both;
              .quoteOrderFormHeader {
                position: relative;
                margin-bottom: 24px;
                display: flex;
                & img {
                  width: 63px;
                  height: 63px;
                  object-fit: cover;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                .quoteOrderFormHeaderText {
                  margin-left: 80px;
                  padding-bottom: 6px;
                  .quoteOrderNum {
                    margin-bottom: 7px;
                    color: $gray50;
                    & span {
                      color: $primaryBlue;
                    }
                  }
                  .requestedOn {
                    color: $gray50;
                    font-weight: $regularFont;
                    line-height: $lineHeight21;
                    & span {
                      font-weight: $mediumFont;
                      color: $gray90;
                    }
                  }
                }
              }
              .PONumberWrapper {
                background: $background7;
                border-radius: 4px;
                padding: 16px;
                padding-bottom: 8px;
                margin-bottom: 16px;
                .attachPOWrapper {
                  & label {
                    display: block;
                    margin-bottom: 3px;
                  }
                  .uploadFileWrapper {
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    border: 1px solid $gray10;
                    border-radius: 8px;
                    height: 48px;
                    max-width: 248px;
                    .fileImage {
                    }
                    .fileName {
                      white-space: nowrap;
                      width: 172px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      font-weight: $mediumFont;
                      font-size: $font14;
                      line-height: $lineHeight21;
                      color: $gray90;
                    }
                    .deleteDocument {
                      height: 20px;
                      cursor: pointer;
                    }
                  }
                  .importButtonWrapper {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    .importButton {
                      padding: 12px 20px;
                      border-radius: 8px;
                    }
                    .inputFile {
                      position: absolute;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      opacity: 0;
                      cursor: pointer;
                    }
                  }
                  // .uploadBtn {
                  //   padding: 12px 55px;
                  // }
                }
              }
              .quoteOrderQuantityWrapper {
                background: $background7;
                border-radius: 4px;
                padding: 8px 24px;
                margin-bottom: 16px;
                .quoteOrderQuantity {
                  padding: 18px 0px;
                  border-bottom: 1px dashed $gray10;
                  .quantityText {
                    font-weight: $regularFont;
                    color: $gray50;
                  }
                  .quantityCount {
                    color: $primaryBlue;
                    text-align: right;
                    & span {
                      padding-left: 4px;
                      color: $primaryBlue;
                    }
                  }
                }
                .quoteSubscriptionPeriod {
                  padding: 18px 0px;
                  .subscriptionText {
                    font-weight: $regularFont;
                    color: $gray50;
                  }
                  .subscriptionCount {
                    color: $primaryBlue;
                    text-align: right;
                    & span {
                      padding-left: 4px;
                      color: $primaryBlue;
                    }
                  }
                }
              }
              .quoteOrderPricingWrapper {
                background: $background7;
                border-radius: 4px;
                padding: 6px 24px;
                margin-bottom: 24px;
                .quoteOrderPricing {
                  padding: 18px 0px;
                  border-bottom: 1px dashed $gray10;
                  .orderDetailsName {
                    color: $gray50;
                  }
                  .orderDetailsPrice {
                    color: $primaryBlue;
                    font-weight: $mediumFont;
                    text-align: right;
                    &.taxPrice {
                      font-size: $font14;
                    }
                    .quoteOrderDiscountTag {
                      font-size: $font10;
                      line-height: $lineHeight21;
                      font-weight: $regularFont;
                      color: $color_success_text;
                      background: $status_green;
                      border-radius: 4px;
                      padding: 4px;
                      margin-right: 10px;
                      vertical-align: middle;
                    }
                    .quoteOrderDiscountNum {
                      font-size: $font14;
                      line-height: $lineHeight21;
                      font-weight: $mediumFont;
                      color: $color_success_text;
                      background: $status_green;
                      border-radius: 4px;
                      padding: 6px 8px;
                      vertical-align: middle;
                    }
                  }
                }
                .quoteOrderTotalPricing {
                  padding: 24px 0px;
                  .orderDetailsTotal {
                    color: $gray90;
                  }
                  .orderDetailsPrice {
                    color: $primaryBlue;
                    font-weight: $semiBoldFont;
                    text-align: right;
                  }
                }
              }
              .quoteOrderFormFooter {
                .quoteAgree {
                  display: inline-flex;
                  margin-bottom: 32px;
                  .quoteAgreeLink {
                    font-weight: $mediumFont;
                    color: $blue;
                    transform: translateY(3px);
                    padding-left: 3px;
                  }
                }
                .quoteOrderReevalText {
                  margin-bottom: 8px;
                  color: $gray70;
                  line-height: $lineHeight21;
                }
                .callBackBtn {
                  border-radius: 8px;
                  padding: 10px 16px;
                  height: 48px;
                  cursor: not-allowed;
                }
                .continueBtn {
                  border-radius: 8px;
                  padding: 12px 55px;
                  height: 48px;
                  float: right;
                }
              }
            }
          }
        }
      }
    }
    .placeOrderWrapper {
      background: #ffffff;
      box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
      border-radius: 6px;
      margin: 24px;
      padding: 36px 24px;
      .placeOrder {
        .ShippingDetail {
          position: relative;
          padding: 0 24px;
          .ShippingTitle {
            @include fontStyle($color: $gray100 !important, $lineHeight: $lineHeight36 !important);
          }
          .orderTitle1 {
            margin: 24px 0;
            color: $gray60;
          }
          .orderTitle2 {
            margin: 30px 0;
            @include fontStyle($color: $gray60 !important);
          }
          .ShippingForm {
            display: flex;
            flex-direction: column;
            .MuiInputBase-input {
              height: 48px;
              border: 1px solid $gray10;
              border-radius: 8px;
              padding: 14px 16px;

              &::placeholder {
                @include fontStyle($color: $gray40);
              }
            }
          }
          .checkBox {
            color: #595959;
            font-size: $font14;
            line-height: $lineHeight18 !important;
            margin: 0;
            & :global(.PrivateSwitchBase-root-5) {
              color: $gray20;
              padding: 0 8px 0 0;
              border-radius: none;
            }
            & span {
              font-size: $font14;
            }
            & :global(.PrivateSwitchBase-checked-6) {
              color: $blue;
            }
          }
          .shippingCardWrapper {
            background: $background4;
            border: 1px solid $gray10;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 16px 20px 20px 16px;
            .addressCardDetailsItem {
              margin-bottom: 16px;
              &:last-child {
                margin-bottom: 0px;
              }
              .addressCardDetailsLeft {
                .addressCardDetailsLeftText {
                  color: $gray50;
                  line-height: $lineHeight21;
                  font-weight: $regularFont;
                }
              }
              .addressCardDetailsRight {
                .addressCardDetailsRightText {
                  color: $primaryBlue;
                  line-height: $lineHeight21;
                  word-break: break-word;
                }
              }
            }
          }
        }
        .addressCardWrapper {
          padding: 24px;
          .addressCardHeadingWrapper {
            margin-bottom: 20px;
            .addressCardHeading {
              color: $gray60;
              display: inline-block;
            }
            .editBtn {
              padding: 4px 20px;
              float: right;
              position: relative;
              top: -4px;
            }
          }
          .addressCardDetailsItemWrapper {
            background: $background4;
            border: 1px solid $gray10;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 16px 20px 20px 16px;
            .addressCardDetailsItem {
              margin-bottom: 16px;
              &:last-child {
                margin-bottom: 0px;
              }
              .addressCardDetailsLeft {
                .addressCardDetailsLeftText {
                  color: $gray50;
                  line-height: $lineHeight21;
                  font-weight: $regularFont;
                }
              }
              .addressCardDetailsRight {
                .addressCardDetailsRightText {
                  color: $primaryBlue;
                  line-height: $lineHeight21;
                  word-break: break-word;
                }
              }
            }
          }
        }
        .buttonSection {
          display: flex;
          gap: 24px;
          padding: 24px 32px;
          .firstBtn {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          .backBtn {
            padding: 12px 36px;
            border: 1px solid $blue;
            border-radius: 8px;
            @include fontStyle(
              $fontFamily: $font_Inter,
              $size: $font14,
              $weight: $mediumFont,
              $color: $blue,
              $lineHeight: $lineHeight21
            );
          }
        }
        .orderSummaryWrapper {
          .orderSummaryCard {
            background: $background4;
            border-radius: 12px;
            .cardHeader {
              padding: 24px 32px;
              padding-bottom: 16px;
              .orderSummaryHeading {
                color: $gray90;
                display: inline-block;
              }
              .editBtn {
                padding: 4px 20px;
                float: right;
                position: relative;
                top: -2px;
              }
            }
            .cardBody {
              padding: 24px 32px;
              padding-top: 0;
              .orderSummaryBlk {
                background: $white;
                border: 1px solid $background5;
                border-radius: 8px;
                padding: 18px 28px;
                margin-bottom: 16px;
                & img {
                  height: 60px;
                  object-fit: cover;
                }
                .orderSummaryBlkDetails {
                  .orderSummaryQuantity {
                    color: $gray50;
                    line-height: $lineHeight21;
                    font-weight: $regularFont;
                    margin: 6px 0px 8px 0px;
                    word-break: break-word;
                  }
                  .orderSummaryQuantityCount {
                    color: $primaryBlue;
                    line-height: $lineHeight21;
                    word-break: break-word;
                    font-size: $font14;
                    & span {
                      font-weight: $regularFont;
                      font-size: $font12;
                    }
                  }
                  .orderSummarySubscription {
                    color: $gray50;
                    line-height: $lineHeight21;
                    font-weight: $regularFont;
                    margin: 6px 0px 8px 0px;
                    word-break: break-word;
                  }
                  .orderSummarySubscriptionPeriod {
                    color: $primaryBlue;
                    line-height: $lineHeight21;
                    word-break: break-word;
                    font-size: $font14;
                    & span {
                      font-weight: $regularFont;
                      font-size: $font12;
                    }
                  }
                }
              }
              .orderSummaryListWrapper {
                .orderSummaryListOuterBlk {
                  border-bottom: 1px solid $background5;
                  .orderSummaryListInnerlk {
                    margin-bottom: 12px;
                    &:last-child {
                      margin-bottom: 18px;
                    }
                    .orderSummaryDescription {
                      & p {
                        color: $gray50;
                        font-weight: $regularFont;
                        line-height: $lineHeight21;
                      }
                    }
                    .orderSummaryPrice {
                      & p {
                        text-align: right;
                        color: $primaryBlue;
                        font-weight: $mediumFont;
                        line-height: $lineHeight21;
                        .discountLabel {
                          text-align: center;
                          margin-right: 8px;
                          border-radius: 3px;
                          padding: 1px 5px;
                          background: $status_green;
                          color: $color_success_text;
                        }
                      }
                    }
                  }
                }
                .orderSummaryTotalBlk {
                  margin-top: 20px;

                  .orderSummaryTotalText {
                    & p {
                      font-weight: $mediumFont;
                    }
                  }
                  .orderSummaryTotalPrice {
                    & p {
                      color: $primaryBlue;
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .quotationStatusBlk {
      background-repeat: no-repeat;
      .quotationStatus {
        background-color: #fff;
        margin-top: 50px;
        border-radius: 4px;
        text-align: center;
        .statusImage {
          background-color: $status_green;
          padding: 50px 0 5px 0;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
        .statusBody {
          .statusTitle {
            padding: 24px 0 8px 0;
            color: $gray100;
            line-height: $lineHeight36;
          }
          .statusSubtext {
            color: $gray50;
            font-weight: $mediumFont;
            & span {
              font-weight: $boldFont;
              color: $gray70;
            }
          }
          .statusButtonWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px 0;
            gap: 20px;
            .actionBtn {
              padding: 14px 18px;
            }
          }
        }
      }
    }
  }
  .quotationsHomePage {
    padding: 60px 0px 0px 240px;
  }
}
