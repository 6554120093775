@import "variable";
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .OrderDetail {
      .OrderDetailWrapper {
        .itemNumber {
          display: inline-block !important;
        }
        .quantityTitle {
          margin-top: 20px !important;
        }
        .subscriptionPeriodBlk {
          .subNumber {
            display: block !important;
            & li {
              width: 100% !important;
              margin-bottom: 12px;
              & > div {
                width: 100% !important;
              }
            }

            .subButton2 {
              .discountBanner {
                top: 0px !important;
                left: -4px !important;
              }
            }
          }
        }
      }
    }
    .ShippingDetail {
      .billingAddressHeader {
        margin-bottom: 20px !important;
      }
    }
  }
}
body {
  // Alok
  .OrderDetail {
    padding: 0 24px;
    .OrderDetailWrapper {
      .orderTitle {
        @include fontStyle($color: $gray100 !important, $lineHeight: $lineHeight36 !important);
      }
      .quantityTitle {
        margin: 48px 0 12px 0;
        @include fontStyle($color: $gray70 !important);
      }
      .itemNumber {
        list-style: none;
        display: flex;
        gap: 8px;
        & li {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .numberButton {
        border: 1px solid #e6e8e9;
        border-radius: 8px;
        cursor: pointer;
        @include fontStyle($color: $gray100 !important);

        &:active {
          border: 1px solid $blue;
          @include fontStyle($color: $blue !important);
        }
        &.active {
          border: 1px solid $blue;
          @include fontStyle($color: $blue !important);
        }
      }
      .moreQuantity {
        margin: 24px 0 40px 0;
        @include fontStyle($size: $font12, $color: $gray50 !important);

        .moreLink {
          margin: 0px 8px;
          cursor: pointer;
          @include fontStyle($weight: $semiBoldFont !important, $color: $blue !important);
        }
      }
      .subTitle {
        @include fontStyle($color: $gray70 !important);
      }
      .subscriptionPeriodBlk {
        .subNumber {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          gap: 24px;
          margin: 12px 0 20px 0;

          & li {
            width: 160px;
            height: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .subButton1 {
            border: 1px solid #e6e8e9;
            border-radius: 8px;
            cursor: pointer;
            @include fontStyle($color: $gray80 !important);
          }
          .subButton2 {
            position: relative;
            .discountBanner {
              position: absolute;
              top: 4px;
              left: -8px;
              padding: 6px;
              font-size: 0;
              width: 100px;
              background-image: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/discountBanner.svg);
              background-repeat: no-repeat;
              background-size: contain;
              text-align: center;
              @include fontStyle(
                $fontFamily: $font_Inter,
                $size: $font10,
                $weight: $boldFont,
                $color: $white,
                $lineHeight: $lineHeight12
              );
            }
          }
        }
      }
      .subInfo {
        display: flex;
        align-items: flex-start;
        padding: 10px 16px;
        background: #ecf6ff;
        border: 1px solid #4c89cd;
        border-radius: 4px;
        & img {
          padding-top: 4px;
        }
        .subInfoText {
          padding-left: 8px;
          @include fontStyle($color: $gray70 !important);
        }
      }
      .subBtn {
        margin-top: 36px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  .ShippingDetail {
    position: relative;
    padding: 0 24px;

    .ShippingTitle {
      @include fontStyle($color: $gray100 !important, $lineHeight: $lineHeight36 !important);
    }

    .orderTitle1 {
      margin: 24px 0;
      color: $gray60;
    }

    .formWrapper {
      position: relative;
      .signInSection {
        position: absolute;
        top: 0;
        right: 0;
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font12,
          $weight: $regularFont,
          $color: $gray50,
          $lineHeight: $lineHeight21
        );

        .signInLink {
          padding-left: 5px;
          cursor: pointer;
          @include fontStyle($weight: $mediumFont, $color: $blue);
        }
      }
    }

    .orderTitle2 {
      margin: 30px 0;
      @include fontStyle($color: $gray60 !important);
    }

    .ShippingForm {
      display: flex;
      flex-direction: column;

      /* .formLabel {
        display: block;
        //margin: 24px 0 3px 0;
      } */

      input {
        height: 48px;
        border: 1px solid $gray10;
        border-radius: 8px;
        padding: 14px 16px;

        &::placeholder {
          @include fontStyle($color: $gray40);
        }
      }

      .buttonSection {
        margin-top: 32px;
        display: flex;
        gap: 24px;

        .firstBtn {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .backBtn {
          padding: 12px 36px;
          border: 1px solid $blue;
          border-radius: 8px;
          @include fontStyle(
            $fontFamily: $font_Inter,
            $size: $font14,
            $weight: $mediumFont,
            $color: $blue,
            $lineHeight: $lineHeight21
          );
        }
      }
    }

    .checkBox {
      color: $gray80;
      font-size: $font14;
      line-height: $lineHeight18 !important;
    }
  }
}
