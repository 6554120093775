@import "variable";

body {
    .dashboardHomeWrapper{
        .contentHeading{
            padding: 16px 24px;
            color: $gray100;
            border: 1px solid $gray10;
        }
    }
}
