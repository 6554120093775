@import "../variable";
body {
    .partnerPage {
    padding: 60px 0px 0px 240px;
    .partnerListWrapper {
      .partnerListWrapper {
        .partnerHeading {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
          .partnerTitle {
            color: $primaryBlue;
          }
        }
        .partnerListStatus{
            .partnerListFilter {
                display: flex;
                padding: 0;
                margin: 0 24px;
                border-bottom: 1px solid $gray10;
                .filterItem{
                  width: auto;
                  padding: 16px;
                  font-weight: $mediumFont;
                  text-transform: capitalize;
                  cursor: pointer;
                  border-bottom: 2px solid $white;
                }
                & :global(.Mui-selected) {
                  border-bottom: 2px solid $blue;
                }
                & :global(.MuiTabs-indicator) {
                  display: none;
                }
            }
        }
        .partnerSearchBlk {
          padding: 16px 24px;
          padding-bottom: 0px;
          .partnerSearchInput {
            position: relative;
            width: 50%;
            float: right;
            & input {
              background: $white;
              border: none;
              height: 32px;
              padding: 14px 8px;
              font-size: $font12;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }
        .partnerList {
          padding: 0px 24px 24px;
          .partnerListInnerBlk {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;

            .partnerListTableTitle {
              padding: 16px ;
              border-bottom: 1px solid $gray10;
              .partnerTableTitleTextWrapper {
                position: relative;
                display: inline-block;
                .partnerTableTitleText {
                  line-height: $lineHeight21;
                  color: $gray50;
                  font-weight: $regularFont;
                  display: inline-block;
                }
                & img {
                  position: absolute;
                  right: -16px;
                  top: 4px;
                  cursor: pointer;
                  padding: 4px;
                }
              }
            }
            .partnerListTableContent {
              padding-bottom: 40px;
              .partnerListItem {
                border-bottom: 1px solid $gray10;
                display: block;

                .partnerListTableRow {
                  padding:16px;
                  .partnerContentWrapper {
                    position: relative;
                    display: flex;
                    align-items: center;
                    .partnerDetailWrapper{
                        padding-left: 16px;
                        .partnerName{
                            font-weight: $mediumFont;
                        }
                        .partnerEmail{
                            font-weight: $regularFont;
                            color: $gray50;
                        }
                    }
                    .partnerUserImg {
                      height: 28px;
                      object-fit: cover;
                    }
                    .companyName {
                      color: $gray90;
                    }
                    .numberCounts {
                      color: $gray90;
                      font-weight: $mediumFont;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .partnerDetails {
    padding: 60px 0px 0px 240px;
    .backBtnWrapper {
      padding: 16px 24px;
      border-bottom: 1px solid $gray10;
    }
    .partnerDetails {
      background: $white;
      padding: 32px 24px;
      .partnerDetailsNameWrapper {
        .partnerName {
          color: $primaryBlue;
        }
        & span{
            display: flex;
            color: $gray90;
            & img{
                padding-right: 8px;
            }
        }
      }
      .partnerDetailNameWrapper{
        display: flex;
        .partnerDetailName{
            color: $gray90;
            padding-left: 8px;
        }
      }
      .partnerTitle{
        color: $gray50;
        font-weight: $regularFont;
      }
      .partnerDetailName{
        color: $gray90;
      }
    }
    .partnerDetailsCardWrapper {
      padding: 24px;
      .partnerDetailsCardBlk {
        .partnerDetailsCard {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;
          padding: 16px;
          position: relative;
          .partnerCardWrapper {
            position: relative;
            min-height: 48px;
            & img {
              position: absolute;
              top: 0;
              left: 0;
              width: 48px;
              height: 48px;
              object-fit: cover;
            }
            .partnerCardContent {
              margin-left: 72px;
              padding-right: 24px;
              & p {
                color: $gray50;
                margin-bottom: 4px;
              }
              & span {
                color: $primaryBlue;
              }
            }
          }
          .partnerCardArrow {
            & img {
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
  & :global(.MuiDrawer-paper){
        .partnerDrawerDetailWrapper{
            background-color: $white;
            width: 520px;
            .partnerDrawerDetailTitle{
                background-color: $background9;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
                .partnerDrawerDetailHeader{
                    color:$gray90;
                }
                & img{
                    cursor: pointer;
                }
            }
            .partnerDetailWrapper{
                padding: 24px;
                height: calc(100vh - 150px);
                overflow-y: auto;
                overflow-x: hidden;
                .partnerDetailBlk{
                  background-color: $background4;
                  border-radius: 8px;
                  & ul{
                    padding: 24px;
                    & li{
                      padding: 0;
                    }
                  }
                  .customerName {
                    padding-left: 8px;
                    color: $primaryBlue;
                  }
                  & span{
                      display: flex;
                      color: $gray90;
                      & img{
                        padding-right: 8px;
                      }
                  }
                  .emailblk{
                    padding: 24px 0 16px 0;
                  }
                }
                .partnerOrgDetail{
                  padding: 24px;
                  .partnerDetailNameWrapper{
                    display: flex;
                    .partnerDetailName{
                        color: $gray90;
                        padding-left: 8px;
                    }
                  }
                  .partnerTitle{
                    color: $gray50;
                    font-weight: $regularFont;
                    padding-bottom: 16px;
                  }
                  .partnerDetailName{
                    color: $gray90;
                  }
                  .enquiryDetailTitles{
                      font-size: $font12;
                      color: $gray50;
                  }
                }
            }
            .partnerButtonWrapper{
                position: absolute;
                padding: 12px 24px;
                bottom: 0;
                width: 100%;
                text-align: right;
                background-color: $background8;
                border-top: 1px solid $gray10;
                .approvePartnerBtn{
                    padding: 14px 70px;
                }
            }
        }
    }
}