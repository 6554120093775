@import "variable";
body {
  .workspacesPage {
    padding: 114px 0px 0px 240px;
    //background: linear-gradient(180deg, #F7FBF9 0%, #ECF3F8 100%);
    .workspacesWrapper {
      .addWorkspaceImg {
        padding: 56px 0 42px 0;
      }
      .workspacesHeading {
        .workspacesTitle {
          color: $gray100;
          padding: 16px 24px;
        }
        border-bottom: 1px solid $gray10;
      }
      .addWorkspaceWrapper {
        text-align: center;
      }
      .addWorkspaceTitle {
        color: $gray90;
      }
      .addWorkspaceSubTitle {
        padding-top: 4px;
        color: $gray50;
      }
      .addWorkspaceButtonWrapper {
        padding: 24px 0 48px;
        display: flex;
        justify-content: center;
        .buttonUpload {
          padding: 12px 16px;
          border: 1px solid $blue;
          display: flex;
          & img {
            padding-right: 8px;
          }
        }
        .buttonAdd {
          margin: 0 16px;
          padding: 12px 16px;
          border-radius: 8px;
          display: flex;
          & img {
            padding-right: 8px;
          }
        }
      }
      .addWorkspaceDownload {
        color: $gray50;
        padding-bottom: 100px;
        .addWorkspaceLink {
          color: $blue;
          font-weight: $mediumFont;
          padding: 6px;
        }
      }
      .workspaceSearchBlk {
        padding: 16px 0;
        margin: 0 24px;
        .workspaceSearchWrapper {
          display: flex;
          gap: 16px;
          justify-content: flex-end;
          .workspaceSearchInput {
            position: relative;
            width: 160px;

            & input {
              box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
              background: $white;
              border: none;
              height: 32px;
              border-radius: 4px;
              padding: 14px 8px;
              font-size: $font12;
            }
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }
        .newWorkspace {
          .newWorkspaceButton {
            position: relative;
            padding: 5px 24px;
            border-radius: 4px;
          }
        }
      }
      .noWorkspaceWrapper {
        text-align: center;
        & img {
          margin: 42px 444px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .noWorkspace {
          color: $gray90;
        }
        .noWorkspaceSubpara {
          color: $gray50;
        }
        .newWorkspaceBtnWrapper {
          .newWorkspaceBtn {
            position: relative;
            margin-top: 24px;
            margin-bottom: 54px;
            padding: 8px 14px;
            border-radius: 4px;
          }
        }
      }
    }

    .workspacesList {
      padding: 0px 24px 24px;
      .workspacesListInnerBlk {
        background: $white;
        box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
        border-radius: 6px;
        .workspacesListTableTitle {
          padding: 16px 16px;
          border-bottom: 1px solid $gray10;
          .workspacesTableTitleTextWrapper {
            display: flex;
            justify-content: flex-end;
            &:first-child {
              justify-content: flex-start;
            }
            .workspacesTableTitleText {
              font-size: $font12;
              color: $gray50;
            }
            // & img {
            //   padding-left: 4px;
            //   cursor: pointer;
            // }
          }
        }
        .workspacesListTableContent {
          .workspaceListItem {
            border-bottom: 1px solid $gray10;
            display: block;
            cursor: pointer;
            @include transitionForAll($time: 0.3s, $transProp: ease-in);
            &:hover {
              box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
              /* .moreAction {
                display: block;
              } */
            }
            &:last-child {
              //border-bottom: none;
            }
            .workspacesListTableRow {
              padding: 16px;
              .workspaceContentWrapper {
                display: flex;
                .workspaceImageWrapper {
                  position: relative;
                  .workspaceBox {
                    padding: 8px;
                    border: 1px solid $gray10;
                    border-radius: 4px;
                  }
                }
                .workspaceDetailWrapper {
                  padding-left: 12px;
                  display: flex;
                  align-items: center;
                  .workspaceName {
                    font-weight: $mediumFont;
                  }
                }
                .workspaceAddress {
                  color: $gray50;
                  font-size: $font12;
                  padding-top: 8px;
                  word-break: break-word;
                }
                .workspaceRoom,
                .workspaceAdmin {
                  color: $gray90;
                  font-size: $font12;
                  font-weight: $mediumFont;
                  padding-top: 8px;
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
    }
  }

  .addWorkspaceDialogWrapper {
    .addWorkspaceTitleWrapper {
      background-color: rgba($gray10, 0.2);
      border-bottom: 1px solid $gray10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .addWorkspaceTitle {
        display: flex;
        align-items: center;
        .addWorkspaceHeader {
          color: $gray100;
          padding-left: 8px;
        }
      }
      .closeDialog {
        cursor: pointer;
      }
    }
    .addWorkspaceTabWrapper {
      margin: 0 24px;
      display: flex;
      border-bottom: 1px solid $gray10;
      .uploadCSV,
      .addManually {
        width: auto;
        padding: 16px;
        font-weight: $mediumFont;
        text-transform: capitalize;
        cursor: pointer;
        border-bottom: 2px solid $white;
      }
      & :global(.Mui-selected) {
        border-bottom: 2px solid $blue;
      }
      & :global(.MuiTabs-indicator) {
        display: none;
      }
    }
    .addWorkspaceContent {
      margin: 24px;
      padding: 0 0 130px 0;
      background-color: rgba($status_blue, 0.23);
      border: 1px dashed rgba(#4c89cd, 0.23);
      border-radius: 4px;
      text-align: center;
      .WorkspacePicture {
        padding: 48px 233px 28px;
      }
      .WorkspaceLink {
        padding-bottom: 28px;
        & span {
          cursor: pointer;
          color: $blue;
        }
      }
      .WorkspaceSample {
        display: flex;
        justify-content: center;
        & span {
          padding-left: 8px;
          color: $blue;
        }
      }
    }
  }
  .addManuallyWrapper {
    padding: 12px 0 0;
    .addWorkspaceForm {
      padding: 0 0 20px;
      margin: 0 24px;
      border-bottom: 1px solid $gray10;
    }
    .addWorkspaceRooms {
      border-bottom: 1px solid $gray10;
      padding: 12px 0 32px;
      margin: 0 24px;
      .subTitle {
        margin: 0 0 3px 16px;
        color: $gray70;
      }
      .quantitySection {
        display: flex;
        align-items: center;
        margin-left: 16px;
        .decrementButton {
          //padding:12px;
          border-radius: 8px 0px 0px 8px;
          border: 1px solid $gray10;
          & img {
            margin: 12px;
          }
          &:active {
            border: 1px solid $blue;
            background: $background1;
          }
        }
        & input[type="number"] {
          height: 53px;
          width: 120px;
          color: $gray100;
          text-align: center;
          border: none;
          border-bottom: 1px solid $gray10;
          border-top: 1px solid $gray10;
          &:focus {
            background: $background1;
          }
        }
        .incrementButton {
          //padding:12px;
          border: 1px solid $gray10;
          border-radius: 0px 8px 8px 0px;
          & img {
            margin: 12px;
          }
          &:active {
            border: 1px solid $blue;
            background: $background1;
          }
        }
      }
    }
    .addWorkspaceWallpaper {
      padding: 32px 0;
      margin: 0 24px;
      border-bottom: 1px solid $gray10;
      .wallpaperTitle {
        color: $gray80;
      }
      .wallpaperSubTitle {
        padding-top: 4px;
        color: $gray40;
        font-weight: $regularFont;
      }
      .addWallpaperButton {
        padding: 13px 46px;
        border-radius: 8px;
      }
    }
    .addWorkspaceInviteAdmin {
      padding: 32px 24px;
      .inviteAdminButton {
        margin-top: 24px;
        padding: 13px 22px;
        border-radius: 8px;
      }
      & :global(.inputGroup) {
        & label {
          font-weight: $mediumFont;
          color: $gray80;
        }
      }
      .inviteAdminTitle {
        padding: 12px 0 22px 0;
        color: $gray80;
      }
      .inviteAdminProfile {
        display: flex;
      }
      .inviteAdminEmail {
        display: flex;
        padding-left: 12px;
        color: $gray100;
      }
      .inviteAdminStatus {
        text-align: right;
        color: $color_info_text;
      }
    }
    .addWorkspaceActionButton {
      padding: 12px 24px;
      display: flex;
      justify-content: flex-end;
      background-color: $background8;
      border-top: 1px solid $gray10;
      .addButton {
        padding: 13px 30px;
        border-radius: 8px;
        margin-right: 20px;
      }
      .exitButton {
        padding: 13px 60px;
      }
    }
  }
  .setWallpaperWrapper {
    .addWallpaperTitleWrapper {
      background-color: rgba($gray10, 0.2);
      border-bottom: 1px solid $gray10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 24px;
      .addWallpaperTitle {
        display: flex;
        align-items: center;
        .addWallpaperHeader {
          color: $gray4;
          padding-left: 8px;
        }
      }
      .closeDialog {
        cursor: pointer;
      }
    }
    .addWallpaperContentWrapper {
      margin: 48px 24px;
      .addWallpaperFrameWrapper {
        position: relative;
        .frameUpdatedPicture {
          position: absolute;
          object-fit: cover;
          left: 5px;
          top: 5px;
          width: 95%;
          height: 83%;
        }
      }
      .addWallpaperConfigWrapper {
        background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/wallpaper_config_bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0 8px 8px 0;
        padding: 24px 48px;
        .ConfigTitle {
          color: $gray3;
        }
        .ConfigSubTitle {
          padding: 4px 0 16px;
          font-weight: $regularFont;
          color: $gray50;
        }
        .importButtonWrapper {
          position: relative;
          display: inline-block;
          .importButton {
            padding: 9px 20px;
          }
          .inputFile {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
      .wallpaperCounts {
        padding: 56px 0 36px;
      }
      .wallpapers {
        cursor: pointer;
        padding: 8px;
        border: 1px solid $gray10;
        border-radius: 8px;
        &.active {
          border: 2px solid $blue;
        }
      }
    }
    .setWallpaperActionButton {
      padding: 12px 24px;
      display: flex;
      justify-content: flex-end;
      background-color: $background8;
      border-top: 1px solid $gray10;
      .saveButton {
        padding: 13px 72px;
      }
    }
    .appLinkWallpaper{
      background: #f5f9f8;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 5px;
      .appLinkIconContainer{
        display: flex;
        gap: 10px;
      }
      .appLinkText{
        text-overflow: ellipsis;
        overflow: hidden;
        color: blue;
        display: block;
      }
    }
    .wallpapersList {
      position: relative;
      object-fit: cover;
      width: 200px;
      height: 100px;
      cursor: pointer;
      margin-top: 20px;
      .wallpapers {
        max-width: 200px;
        max-height: 200px;
        padding: 6px;
        cursor: pointer;
        border: 1px solid $gray10;
        border-radius: 8px;
        &.active {
          border: 2px solid $blue;
        }
      }
    }
  }

  // Arun
  .workspaceDetailsPage {
    padding: 114px 0px 0px 240px;
    .workspaceDetailsWrapper {
      .backBtnWrapper {
        padding: 16px 24px;
        border-bottom: 1px solid $gray10;
      }
      .workspaceDetailsCardWrapper {
        padding: 16px 24px;
        .workspaceDetailsCard {
          background: $white;
          padding: 16px;
          position: relative;
          .workspaceDetailsIconWrapper {
            position: relative;
            & img {
              width: 48px;
              height: 48px;
              object-fit: cover;
              position: absolute;
              left: 0;
              top: 0;
            }
            .workspaceDetailsTextWrapper {
              margin-left: 64px;
              .workspaceName {
                margin-bottom: 4px;
                color: $primaryBlue;
                font-weight: $mediumFont;
              }
              .workspaceAddress {
                color: $gray80;
                font-weight: $regularFont;
                line-height: $lineHeight21;
              }
            }
          }
          .timezoneText{
            // margin-top: 10px;
            color: $primaryBlue;
            line-height: $lineHeight21;
            margin-bottom: 4px;
            font-weight: $regularFont;
          }
          .timezoneValue{
            color: $gray80;
                font-weight: $regularFont;
                line-height: $lineHeight21;
          }
          .workspaceRoomText {
            color: $gray50;
            line-height: $lineHeight21;
            margin-bottom: 8px;
            font-weight: $regularFont;
          }
          .workspaceRoomCount {
            color: $primaryBlue;
            font-weight: $mediumFont;
          }
          .workspaceLocalAdminWrapper {
            .workspaceLocalAdminText {
              color: $gray50;
              line-height: $lineHeight21;
              margin-bottom: 8px;
              font-weight: $regularFont;
            }
            ul {
              padding: 0;
              li {
                padding: 0;
                margin-bottom: 10px;
                color: $black;
                line-height: $lineHeight21;
                font-weight: $regularFont;
                &:last-child {
                  margin-bottom: 0;
                }
                & img {
                  margin-right: 8px;
                }
              }
            }
            .moreList {
              color: $blue;
              font-weight: $mediumFont;
              margin-left: 8px;
              cursor: pointer;
            }
          }
          .defaultWallpaperWrapper {
            position: relative;
            .workspaceWallpaperText {
              color: $gray50;
              line-height: $lineHeight21;
              margin-bottom: 8px;
              font-weight: $regularFont;
            }
            .workspaceWallpaper {
              width: 60px;
              object-fit: cover;
            }
          }
          .workspaceDetailsMoreOption {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            & img {
              height: 24px;
              width: 24px;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
      .workspaceRoomListWrapper {
        padding: 12px 24px;
        .workspaceRoomListBlk {
          .modal {
            display: none; /* Hidden by default */
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: hidden; /* Enable scroll if needed */
            background-color: rgb(0,0,0); /* Fallback color */
            background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
          }
           /* Modal Content */
      .modalContent {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 30%;
        margin-top: 300px;
      }
       /* The Close Button */
       .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
      }
      
      .close:hover,
      .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
      }
          .workspaceRoomSearchBlk {
            float: right;
            margin-right: 16px;
            .workspaceRoomSearchInput {
              position: relative;
              width: 164px;

              & input {
                background: $white;
                border: none;
                height: 32px;
                padding: 14px 8px;
                font-size: $font12;
                border-radius: 4px;
              }
              & :global(.inputGroup) {
                margin-bottom: 0;
              }
              & img {
                object-fit: cover;
                position: absolute;
                right: 6px;
                top: 7px;
              }
            }
          }
          .WorkspaceAddNewRoomBtn {
            float: right;
            margin-top: 10px;
            .addNewRoomBtn {
              padding: 5px 14px;
              & img {
                vertical-align: middle;
                position: relative;
                top: -1px;
              }
            }
          }
          .WorkspaceAddNewRoomBtnWrapper {
            float: right;
            .addNewRoomBtn {
              padding: 5px 14px;
              & img {
                vertical-align: middle;
                position: relative;
                top: -1px;
              }
            }
          }
          .workspaceRoomListItemWrapper {
            margin-top: 20px;
            margin-bottom: 50px;
            .workspaceRoomListItemBlk {
              background: $white;
              box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
              border-radius: 6px;
              .workspaceRoomListTitleRow {
                padding: 16px;
                border-bottom: 1px solid $gray10;
                .workspaceRoomTableTitleTextWrapper {
                  position: relative;
                  display: inline-block;
                  .workspaceRoomTableTitleText {
                    line-height: $lineHeight21;
                    color: $gray50;
                    font-weight: $regularFont;
                    display: inline-block;
                  }
                  // & img {
                  //   position: absolute;
                  //   right: -16px;
                  //   top: 4px;
                  //   cursor: pointer;
                  //   padding: 4px;
                  // }
                  .sortUp {
                    height: 12px;
                    width: 12px;
                    position: relative;
                    //top: 3px;
                    cursor: pointer;
                    padding-left: 4px;
                  }
                  .sortDown {
                    height: 12px;
                    width: 12px;
                    position: relative;
                    top: 1px;
                    margin-left: -2px;
                    cursor: pointer;
                    padding-left: 4px;
                  }
                }
              }
              .workspaceRoomListContentRow {
                .workspaceRoomListItem {
                  .workspaceRoomListItemLink {
                    position: relative;
                    padding: 16px;
                    display: block;
                    border-bottom: 1px solid $gray10;
                    @include transitionForAll($time: 0.3s, $transProp: ease-in);
                    &:hover {
                      box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
                      .moreAction {
                        display: block;
                      }
                    }
                    .workspaceRoomNameWrapper {
                      position: relative;
                      margin: 12px 0px;
                      & img {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 42px;
                        height: 42px;
                        object-fit: cover;
                      }
                      .workspaceRoomName {
                        color: $primaryBlue;
                        font-weight: $mediumFont;
                        margin-left: 58px;
                        word-break: break-word;
                      }
                    }
                    .workspaceRoomSeatingCapacity {
                      color: $gray90;
                      font-weight: $mediumFont;
                      padding-top: 12px;
                    }
                    .workspaceRoomDeviceStatusWrapper {
                      padding-top: 12px;
                      .workspaceRoomDeviceStatus {
                        font-size: $font12;
                        font-weight: $mediumFont;
                        & img {
                          vertical-align: middle;
                          position: relative;
                          top: -1px;
                        }
                        &.notAssigned {
                          color: $status_orange;
                        }
                        &.assigned {
                          color: $blue;
                        }
                      }
                    }
                    .workspaceRoomAdditionalDevices {
                      padding-top: 12px;
                      color: $gray90;
                    }
                    .moreAction {
                      position: absolute;
                      right: 14px;
                      top: 50%;
                      transform: translateY(-50%);
                      display: none;
                      @include transitionForAll($time: 0.3s, $transProp: ease-in);
                    }
                    &.disabledRow {
                      background: $gray5;
                      opacity: 0.6;
                      cursor: default;
                    }
                  }
                }
              }
            }
          }
          .noMeetingWrapper {
            text-align: center;
            & img {
              padding-top: 8px;
              padding-bottom: 8px;
            }
            .providerNoMeeting {
              color: $gray90;
            }
            .providerNoMeetingSubpara {
              color: $gray50;
            }
          }
          .workspaceAddBtn {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  & :global(.MuiDrawer-paper) {
    .addRoomDrawer {
      position: relative;
      width: 700px;
      height: 100h;
      .drawerHeader {
        background: $background8;
        position: fixed;
        padding: 16px;
        top: 0;
        width: 100%;
        z-index: 99;
        border-bottom: 1px solid $gray10;
        & img {
          position: absolute;
          top: 16px;
          left: 16px;
          cursor: pointer;
          object-fit: cover;
        }
        .drawerHeaderTitle {
          margin-left: 34px;
          color: $primaryBlue;
        }
      }
      .drawerBody {
        padding-top: 70px;
        .addRoomTopBlk {
          padding: 24px 24px 32px 24px;
          border-bottom: 1px solid $gray10;
          .workspaceWrapper {
            margin-bottom: 24px;
            .workspaceText {
              color: $gray50;
              font-weight: $regularFont;
              line-height: $lineHeight21;
            }
            .workspaceLocationText {
              color: $primaryBlue;
            }
          }
          .deviceWrapper {
            margin-bottom: 24px;
            .deviceText {
              color: $gray50;
              font-weight: $regularFont;
              line-height: $lineHeight21;
            }
          }
          .roomNameInputWrapper {
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            .seatingCapacityInput {
              //padding-top: 22px;
            }
          }
        }
        .additionalDevicesBlk {
          padding: 18px 16px;

          padding-bottom: 80px;
          .additionalDevicesTitle {
            font-weight: $mediumFont;
            color: $gray50;
            margin-bottom: 24px;
          }
          .addNewDeviceBtn {
            float: right;
            color: $blue;
            & img {
              vertical-align: middle;
              position: relative;
              top: -1px;
            }
          }
          .additionalDevicesContent {
            margin-bottom: 32px;
            .removeBtn {
              color: $bg_red;
              font-weight: $mediumFont;
              font-size: $font14;
              line-height: $lineHeight21;
              float: right;
              position: relative;
              top: 7px;
            }
            .additionalDeviceName {
              padding-top: 14px;
              & img {
                vertical-align: middle;
                position: relative;
                top: -2px;
                object-fit: cover;
                margin-right: 8px;
              }
              p {
                display: inline-block;
              }
            }
            .additionalDeviceInput {
              & :global(.inputGroup) {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .drawerFooter {
        background: $background8;
        border-top: 1px solid $gray10;
        padding: 12px 16px;
        position: fixed;
        width: 700px;
        bottom: 0;
        & button {
          padding: 14px 80px;
          width: 100%;
          height: 48px;
          border-radius: 8px;
        }
      }
    }
  }
  //Arun
}
