// Font Family
$font_Inter: "Inter", sans-serif;

// Font Weight
$lightFont: 300;
$regularFont: 400;
$mediumFont: 500;
$semiBoldFont: 600;
$boldFont: 700;

$gray1: #eaebec;
$gray2: #a5a5a5;
$gray3: #333333;
$gray4: #193448;
$gray5: #d1d1d1;
$background1: #eceffd;
$background2: #e6e6e7;
$background3: #fff7f7;
$background4: #f8f8f8;
$background5: #f1f1f1;
$background6: #f5f9f8;
$background7: #f7f8f8;
$background8: #fafafb;
$background9: #f2f2f2;
$background10: #eaeefd;

// Wireframe Colours
$black: #000000;
$white: #ffffff;
$gray10: #e6e8e9;
$gray20: #ccd0d4;
$gray30: #b3b9be;
$gray40: #99a2a9;
$gray50: #67737d;
$gray60: #4d5c68;
$gray70: #4d5c68;
$gray80: #344552;
$gray90: #1a2d3d;
$gray100: #011627;

//Prime Colours
$primaryBlue: #011627;
$red: #f43031;
$yellow: #f8cd46;
$blue: #4361ee;
$blue2: #1f44ea;
$powder: #6f8da9;
$bg_red: #cf2116;
$bg_red2: #7e4340;
$powder2: #ecf6ff;

// Status colors
$color_info_text: #4c89cd;
$color_success_text: #2e9940;
$color_warning_text: #f1953f;
$color_error_text: #e83a3a;
$color_error_bg: #fff7f7;
$color_status_green_text: #2e9940;

$status_green: #e9f5ed;
$status_red: #ffecee;
$status_orange: #f1953f;
$status_blue: #ecf6ff;

$color_info_bg: #ecf7ff;
$color_success_bg: #e7fff6;
$color_error_bg: #ffe4e4;
$color_warning_bg: #fff1ce;

//Font Size
$font64: 64px;
$font52: 52px;
$font48: 48px;
$font36: 36px;
$font32: 32px;
$font28: 28px;
$font24: 24px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

// Line Height
$lineHeight96: 96px;
$lineHeight78: 78px;
$lineHeight72: 72px;
$lineHeight54: 54px;
$lineHeight42: 42px;
$lineHeight36: 36px;
$lineHeight28: 28px;
$lineHeight27: 27px;
$lineHeight24: 24px;
$lineHeight21: 21px;
$lineHeight18: 18px;
$lineHeight12: 12px;

@mixin fontStyle(
  $fontFamily: null,
  $size: null,
  $weight: null,
  $color: null,
  $lineHeight: null,
  $margin: null,
  $padding: null
) {
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $size != null {
    font-size: $size;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}

@mixin transitionForAll($time: 0.3s, $transProp: ease) {
  -webkit-transition: all $time $transProp;
  -moz-transition: all $time $transProp;
  -o-transition: all $time $transProp;
  transition: all $time $transProp;
  transition: all $time $transProp;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  25% {
    margin-left: 0.25rem;
  }
  75% {
    margin-left: -0.25rem;
  }
  100% {
    margin-left: 0rem;
  }
}
