@import "variable";
@media only screen and (max-width: 1300px){
  .cardBody{
    .divider{
      display: none;
    }
  }
}

body {
  header {
    background: linear-gradient(235.21deg, #f43044 -78.5%, #011627 38.8%);
    box-shadow: 0px 1.04407px 1.04407px rgba(0, 0, 0, 0.25);
    padding: 8px 0;
    position: sticky;
    z-index: 10;
    top: 0;

    ul {
      text-align: right;
      li {
        margin-right: 32px;
        &:last-child {
          margin-right: 12px;
        }
      }
    }
    .becomePartnerBtn {
      text-transform: none;
    }
  }
  .squareRadioButton {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray10;
    border-radius: 8px;
    color: $gray100;
    cursor: pointer;

    &.mediumButton {
      width: 80px;
      height: 32px;
    }

    &.largeButton {
      width: 160px;
      height: 72px;
      color: $gray80;
    }

    &.active {
      color: $blue;
      border: 1px solid $blue;
    }
  }

  .cardFooter {
    .orderSummaryShippingAddressWrapper {
      background-color: $background4;
      border: 1px solid $gray10;
      border-radius: 8px;
      padding: 16px;
      .shippingAddressHeadingWrapper {
        margin-bottom: 20px;
        .shippingAddressHeading {
          color: $gray60;
          display: inline-block;
        }
        .editBtn {
          padding: 4px 20px;
          float: right;
          position: relative;
          top: -4px;
        }
      }
      .shippingAddressDetailsItemWrapper {
        .shippingAddressDetailsItem {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0px;
          }
          .shippingDetailsLeft {
            .shippingDetailsLeftText {
              color: $gray50;
              line-height: $lineHeight21;
              font-weight: $regularFont;
            }
          }
          .shippingDetailsRight {
            .shippingDetailsRightText {
              color: $primaryBlue;
              line-height: $lineHeight21;
            }
          }
        }
      }
    }
  }

  footer {
    background-color: $gray100;

    .footerWrapper {
      padding: 50px 0;
      border-bottom: 1px solid #1a2d3d;
      .link-items {
        & span {
          color: $gray10;
          a {
            color: $gray10;
          }
        }
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        transition: all 0.4s ease-out;
        &:hover {
          transition: all 0.4s ease-in;
          color: $white;
        }
      }

      .FooterText {
        color: $gray30;
        margin-top: 20px;
      }

      .footerSocialMediaTitle {
        color: $gray30;
        font-weight: $mediumFont;
        padding-bottom: 16px;
      }
      .socialMediaLogo {
        margin-right: 16px;
        img {
          transition: all 0.25s ease-in;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .copyRight {
      padding: 30px 0;
      color: $white;
    }
  }

  .customLink {
    & img {
      vertical-align: middle;
    }
    & span {
      padding-left: 4px;
    }
  }
  .backBtn {
    & img,
    & span {
      vertical-align: middle;
    }
    & span {
      font-size: $font14;
      line-height: $lineHeight21;
      font-weight: $mediumFont;
      color: $gray60;
      margin-left: 12px;
    }
  }

  .PartnerHeader {
    background: $gray100;
    box-shadow: 0px 3px 3px #c9cfd6;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    .headerLogoWrapper {
      width: 100px;
      height: 60px;
      display: flex;
      justify-content: center;
      background: linear-gradient(253.65deg, rgba(244, 48, 49, 0.82) -349.05%, rgba(1, 22, 39, 0.82) 94.07%);
    }
    .headerMenu {
      .menuItems {
        display: flex;
        height: 60px;
        padding: 0;
        .menuItem {
          color: $gray40;
          font-weight: $mediumFont;
          border-bottom: 2px solid $gray100;
          cursor: pointer;
          &.active {
            color: $white;
            border-bottom: 2px solid $blue;
          }
          &:hover {
            color: $white;
            border-bottom: 2px solid $blue;
          }
          .menuItemDropdown {
            padding-left: 4px;
            color: $gray40;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .headerRightBlk {
      .rightBlkItems {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .requestButton {
          padding: 8px 16px;
          font-size: $font12;
          border-radius: 4px;
        }
        & button {
          display: flex;
          align-items: center;
          width: 100%;
        }
        .headerUserProfile {
          margin-left: 8px;
          .headerProfileDropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .userImg {
              width: 28px;
              height: 28px;
              border-radius: 50%;
            }
            .userName {
              padding: 0 0 4px 12px;
              color: $white;
              font-weight: $mediumFont;
              line-height: $lineHeight36;
              display: flex;
              align-items: center;
              & span {
                line-height: $lineHeight21;
                max-width: 140px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
  .logOutDialog {
    z-index: 99999 !important;
    .MuiPaper-root {
      min-width: 400px;
      position: relative;
      padding: 30px;
    }
    svg {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      font-size: 22px;
    }
    h3 {
      color: $gray90;
      font-size: $font24;
    }
    ul {
      text-align: center;
      padding: 8px 0;
      li {
        padding: 0;
        margin-right: 16px;
        button {
          padding: 10px 32px;
          &.cancelBtn {
            background: $background1;
            border-radius: 8px;
            &:hover {
              background: $background2;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .snackbar {
    .toastIcon {
      height: 24px;
      width: 24px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .closeIcon {
      height: 18px;
      width: 28px;
      position: absolute;
      top: 14px;
      right: 12px;
      cursor: pointer;
    }
    > div {
      min-width: 380px;
      padding: 12px 16px;
      background: #d8dfe7;
      // border: 1px solid #778da9;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      padding-right: 40px;
      p {
        padding-left: 28px;
        @include fontStyle($font_Inter, $font14, $semiBoldFont, $lineHeight24);
      }
      &.info {
        background: $color_info_bg;
        border: 1px solid $color_info_text;
        p {
          color: $color_info_text;
        }
      }
      &.success {
        background: $color_success_bg;
        border: 1px solid $color_success_text;
        p {
          color: $color_success_text;
        }
      }
      &.error {
        background: $color_error_bg;
        border: 1px solid $color_error_text;
        p {
          color: $color_error_text;
        }
      }
      &.warning {
        background: $color_warning_bg;
        border: 1px solid $color_warning_text;
        p {
          color: $color_warning_text;
        }
      }
    }
  }
  .organisationDropdownMenu {
    height: 380px;
    .MuiPaper-root {
      position: relative;
      width: 292px;
      top: 56px !important;
      left: 170px !important;
      box-shadow: 0px 6px 8px rgba(103, 115, 125, 0.31);
      border-radius: 6px;
      ul {
        padding: 0;
      }
      .DropdownPointerImg {
        position: absolute;
        background-color: #fff;
        top: -10px;
        left: 50%;
      }
      .organisationDropdownSearchInput {
        & input {
          margin: 16px 16px 0;
          background: $white;
          border: 1px solid $gray10;
          height: 32px;
          border-radius: 4px;
          font-size: $font12;
        }
        &:global(.inputGroup) {
          margin-bottom: 0;
        }
        & img {
          object-fit: cover;
          position: absolute;
          right: 34px;
          top: 20px;
          cursor: pointer;
          padding: 4px 8px 5px 7px;
          background-color: #fff;
        }
      }
      .companyListWrapper {
        margin: 16px;
        .companyRecentListWrapper {
          .companyRecentListTitle {
            font-size: $font12;
            color: $gray50;
          }
          .companyRecentListBlk {
            display: flex;
            padding-bottom: 12px;
            .companyRecentListName {
              color: $gray100;
              font-size: $font12;
              font-weight: $mediumFont;
              padding-left: 8px;
            }
          }
        }
        .companyAllListWrapper {
          .companyAllTitleWrapper {
            display: flex;
            justify-content: space-between;
            margin: 8px 0;
            .companyAllListTitle {
              font-size: $font12;
              color: $gray50;
            }
            .companyAllViewLink {
              padding: 0 8px;
              font-size: $font12;
              color: $blue;
            }
          }
          .companyAllListBlk {
            display: flex;
            padding-bottom: 16px;
            .companyAllListName {
              color: $gray100;
              font-size: $font12;
              font-weight: $mediumFont;
              padding-left: 8px;
            }
          }
        }
      }
      .addOrgButtonWrapper {
        background-color: $background7;
        position: sticky;
        bottom: 0;
        //height: 38px;
        .addButton {
          padding: 8px 16px;
          display: flex;
          color: $blue;
          & img {
            padding-right: 8px;
          }
        }
      }
    }
  }

  .dropdownMenu {
    .MuiPaper-root {
      background: $white;
      box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
      border-radius: 4px;
      top: 55px !important;
      width: 200px;
      left: auto !important;
      right: 50px;
      ul {
        padding: 6px 0px;
        .dropdownMenuItem {
          padding: 0;
          color: $gray3;
          display: block;
          line-height: $lineHeight21;
          font-weight: $regularFont;
          & a {
            color: $gray3;
            padding: 14px 18px;
            display: block;
          }
          & img {
            margin-right: 15px;
            vertical-align: middle;
            position: relative;
            top: -1px;
          }
          &:hover,
          &:focus {
            background: $background1;
          }
        }
      }
    }
  }
  .companyNameSection {
    position: fixed;
    top: 60px;
    width: 100%;
    height: 54px;
    z-index: 99;
    display: flex;
    align-items: center;
    background-color: $white;
    filter: drop-shadow(0px 0px 10px rgba(77, 92, 104, 0.15));
    border-bottom: 1px solid $gray10;
    & img {
      margin: 0 16px 0 24px;
    }
    .companyName {
      color: $gray100;
    }
  }
  .reviewQuotationWrapper {
    padding-bottom: 60px;
    .reviewQuotation {
      background-color: $white;
      box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
      border-radius: 6px;
      .reviewQuotationHeader {
        display: flex;
        align-items: center;
        margin-top: 48px;
        .quotationTitleWrapper {
          margin-left: 16px;
          .quoteNumber {
            color: $gray50;
            & span {
              color: $gray100;
            }
          }
          .quoteDate {
            padding-top: 8px;
            color: $gray50;
            & span {
              color: $gray90;
              font-weight: $mediumFont;
            }
          }
        }
      }
      .quotationDetailBlk,
      .quotationQuantityBlk,
      .quotationTotalBlk {
        background-color: $background7;
        margin: 32px 0 16px;
        padding: 32px;
        border-radius: 4px;
        .quotationDetailListItem {
          display: flex;
          justify-content: space-between;
          padding: 16px 0;
          border-bottom: 1px dashed $gray10;
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          &:first-child {
            padding-top: 0;
          }
          .listItemTitle {
            color: $gray50;
          }
          .listItemValue {
            font-weight: $mediumFont;
            & span {
              font-size: $font16;
              line-height: $lineHeight24;
              font-weight: $semiBoldFont;
            }
          }
          .listItemTotalValue {
            color: $gray100;
          }
        }
      }
      .quotationDiscountBlk {
        background-color: $status_blue;
        margin: 32px 0 16px;
        padding: 32px 24px;
        border-radius: 4px;
        .quotationDetailListItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          .listItemTitle {
            color: $gray50;
          }
          .listItemValue {
            font-weight: $mediumFont;
          }
          & .inputGroup {
            margin-bottom: 0;
            width: 108px;
          }
          & input {
            text-align: center;
          }
        }
      }
      .quotationFooterWrapper {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 16px 0 24px;
        .quotationSharedWrapper {
          position: absolute;
          left: 24px;
        }
        .quotationButtonWrapper {
          display: flex;
          justify-content: flex-end;
          .backQuoteBtn {
            padding: 14px 36px;
            border-radius: 8px;
            margin-right: 24px;
          }
          .actionBtn {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .sideBar {
    width: 240px;
    height: 100%;
    position: fixed;
    top: 114px;
    left: 0;
    background-color: $white;
    filter: drop-shadow(0px 0px 1px rgba(77, 92, 104, 0.15));
    .sidebarWrapper {
      padding-left: 10px;
      .menuListBlk {
        margin-top: 12px;
        .listItem {
          padding: 0;
          margin-top: 4px;
          border-right: 3px solid $white;
          & a {
            width: 100%;
            display: flex;
            align-items: center;
            .activeImg,
            .defaultImg {
              padding: 16px;
            }
            .activeImg {
              display: none;
            }
            .defaultImg {
              display: block;
            }
            & p {
              font-size: $font14;
              color: $gray40;
            }
          }
          &.active,
          &:hover {
            background-color: $background1;
            border-radius: 4px 0 0 4px;
            border-right: 3px solid $blue;
            cursor: pointer;
            & p {
              color: $gray100;
            }
            .activeImg {
              display: block;
            }
            .defaultImg {
              display: none;
            }
          }
        }
      }
    }
  }
  .homeSidebar {
    width: 240px;
    height: 100%;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: $white;
    .sidebarWrapper {
      padding-left: 10px;
      .menuListBlk {
        margin-top: 12px;
        .listItem {
          padding: 0;
          margin-top: 4px;
          border-right: 3px solid $white;
          & a {
            width: 100%;
            display: flex;
            align-items: center;
            .activeImg,
            .defaultImg {
              padding: 16px;
            }
            .activeImg {
              display: none;
            }
            .defaultImg {
              display: block;
            }
            & p {
              font-size: $font14;
              color: $gray40;
            }
          }
          &.active,
          &:hover {
            background-color: $background1;
            border-radius: 4px 0 0 4px;
            border-right: 3px solid $blue;
            cursor: pointer;
            & p {
              color: $gray100;
            }
            .activeImg {
              display: block;
            }
            .defaultImg {
              display: none;
            }
          }
        }
      }
    }
  }
  .devicesCard {
    background: $white;
    box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
    border-radius: 6px;
    padding: 24px;
    .devicesTitle {
      color: $gray50;
    }
    .cardBody {
      .activatedDevicesWrapper {
        position: relative;
        padding: 36px 0px;
        .deviceImgWrapper {
          position: absolute;
          left: 0;
          top: 36px;
          & img {
            width: 85px;
            height: 85px;
            object-fit: cover;
          }
          .deviceStatusIconWrapper {
            position: absolute;
            bottom: 22px;
            right: -20px;
            & img {
              width: 44px;
              height: 44px;
              object-fit: cover;
            }
          }
        }
        .activatedDeviceTextWrapper {
          display: inline-block;
          margin-left: 130px;
          margin-top: 26px;

          .activatedDevicesText {
            color: $gray50;
            margin-bottom: 4px;
          }
          .activatedDevicesCount {
            color: $primaryBlue;
          }
        }
      }
      .deviceStatusRightBlk {
        padding: 24px;
        .deviceStatusBlk {
          display: block;
          position: relative;
          margin-bottom: 48px;
          &:last-child {
            margin-bottom: 0px;
          }
          .deviceImgWrapper {
            position: absolute;
            left: 0;
            top: 0;
            .deviceImg {
              width: 50px;
              height: 50px;
              object-fit: cover;
            }
            .deviceStatusIcon {
              width: 32px;
              height: 32px;
              object-fit: cover;
              position: absolute;
              left: 35px;
              top: 10px;
            }
          }
          .textBlkWrapper {
            margin-left: 80px;
            .notActivatedDevicesText {
              color: $gray50;
              margin-bottom: 4px;
            }
            .notActivatedDevicesCount {
              color: $primaryBlue;
            }
          }
        }
      }
    }
  }
  .avatar {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    line-height: $lineHeight28;
    background: $gray10;
    color: $gray50;
    font-size: $font14;
    font-weight: $mediumFont;
  }
  main > div {
    padding-left: 240px;
  }
  .workspaceStatus {
    font-size: $font12;
    font-weight: $mediumFont;
    cursor: default !important;
    & img {
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
    &.notAssigned {
      color: $status_orange;
    }
    &.assigned {
      color: $blue;
    }
  }
  .customIncrementInput {
    display: flex;
    align-items: center;
    .decrementButton {
      padding: 12px;
      border: 1px solid $gray10;
      border-radius: 8px 0 0 8px;
      &:active {
        border: 1px solid $blue;
        background: $background1;
      }
      & img {
        vertical-align: middle;
      }
    }
    & input[type="number"] {
      height: 50px;
      color: $gray100;
      text-align: center;
      border: none;
      border-bottom: 1px solid $gray10;
      border-top: 1px solid $gray10;
      &:focus {
        background: $background1;
      }
    }
    .incrementButton {
      padding: 12px;
      border: 1px solid $gray10;
      border-radius: 0 8px 8px 0;
      &:active {
        border: 1px solid $blue;
        background: $background1;
      }
      & img {
        vertical-align: middle;
      }
    }
  }
  .noResultFound {
    min-height: calc(100vh - 200px);
    img {
      margin-bottom: 24px;
    }
    .noStateHeading {
      font-size: $font18;
      font-weight: $semiBoldFont;
      color: $gray80;
      line-height: $lineHeight27;
      margin-bottom: 12px;
    }
    .noStateSubHeading {
      font-size: $font14;
      font-weight: $regularFont;
      color: $gray50;
      line-height: $lineHeight21;
      span {
        font-weight: $semiBoldFont;
      }
    }
    p {
      margin-top: 32px;
    }
    &.noResultDefault {
      min-height: auto;
      padding: 36px 0;
    }
    &.noResultViewCenter {
      height: calc(100vh - 130px);
      background: $background1;
    }
  }
  .sortUp {
    height: 12px;
    width: 12px;
    position: relative;
    top: 3px;
    cursor: pointer;
    padding-left: 4px;
  }
  .sortDown {
    height: 12px;
    width: 12px;
    position: relative;
    top: 4px;
    margin-left: -2px;
    cursor: pointer;
    padding-left: 4px;
  }
  .wallpaperDialogWrapper {
    .MuiDialog-scrollPaper {
      .MuiDialog-paperWidthSm {
        max-width: 1000px;
      }
    }
  }
  .dashboardTableWrapper {
    width: 100%;
    margin-top: 30px;
    .tableHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 98%;
      margin: auto;
      .tableTitle {
        padding: 13px;
        padding-top: 20px;
      }
      .tableInputs {
        display: flex;
        padding-top: 4px;
      }
    }
    .tableBody {
      .tableCell {
        display: flex;
        justify-content: space-around;
        width: 200px;
      }
    }
  }
  .dashboardMeetingsWrapper {
    margin-top: 30px;
    width: 100%;
    margin-top: 30px;
    .paperContainer {
      padding-bottom: 14px;
      .meetingHeader {
        display: flex;
        justify-content: space-between;
        width: 98%;
        margin: auto;
        align-items: center;
        .meetingTitle {
          padding: 13px;
          padding-top: 20px;
        }
      }
      .gridItem {
        display: flex;
        .gridSecondChild {
          margin-left: 10px;
        }
      }
    }
  }
  .dashboardLastHalfWrapper {
    margin-top: 30px;
    width: 100%;
    margin-top: 30px;
    .offlineSharePaper {
      padding: 14px;
      .title {
        padding: 13px;
        padding-top: 10px;
      }
      .trends {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 180px;
      }
    }
    .orderWrapper {
      margin-top: 30px;
      width: 100%;
      .paperContainer {
        padding: 18px;
        .title {
          margin-top: 4px;
          margin-left: 7px;
        }
        .orderContainer {
          margin-top: 14px;
          .orderItem {
            .img {
              display: flex;
              justify-content: center;
            }
            .title {
              padding-top: 20px;
            }
            .count {
              font-weight: bold;
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
  .orderWrapper {
    margin-top: 30px;
    width: 100%;
    .paperContainer {
      padding: 18px;
      .title {
        margin-top: 4px;
        margin-left: 7px;
      }
      .orderContainer {
        margin-top: 14px;
        .orderItem {
          .img {
            display: flex;
            justify-content: center;
          }
          .title {
            padding-top: 20px;
          }
          .count {
            font-weight: bold;
            padding-top: 4px;
          }
        }
      }
    }
  }
  .eChart {
    margin-top: -47px;
    @media screen and (max-width: 950px) {
      margin-top: 40px;
    }
  }
}
