@media (min-width: 1280px) {
  body{
    .vedioDialog{
      .MuiDialog-paperWidthMd{
        width: 50%;
        max-height: calc(100% - 32px);
        video{
          height: 650px;
        }
      }
      &.vedioDialogWebsite{
        .MuiDialog-paperWidthMd{
          width: auto;
          // max-height: calc(100% - 64px);
        }
      }
    }
  }
}

@media (min-width: 1350px) {
  body{

  }
}


@media (min-width: 1700px) {
  body{

  }
}
@media (min-width: 2000px) {
  body{

  }
}

@media (min-width: 2800px) {
  body{

  }
}
