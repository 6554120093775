@import "../variable";
body {
  .directOrderPage {
    padding: 60px 0px 0px 240px;
    .directOrderWrapper {
      .directOrderListWrapper {
        .directOrderHeading {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
          .directOrderTitle {
            color: $primaryBlue;
          }
        }
        .directOrderSearchBlk {
          padding: 16px 24px;
          padding-bottom: 0px;
          .directOrderSearchInput {
            position: relative;
            width: 50%;
            float: right;
            & input {
              background: $white;
              border: none;
              height: 32px;
              padding: 14px 8px;
              font-size: $font12;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }
        .directOrderList {
          padding: 0px 24px 24px;
          .directOrderListInnerBlk {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;

            .directOrderListTableTitle {
              padding: 16px 26px;
              border-bottom: 1px solid $gray10;
              .directOrderTableTitleTextWrapper {
                position: relative;
                display: inline-block;
                .directOrderTableTitleText {
                  line-height: $lineHeight21;
                  color: $gray50;
                  font-weight: $regularFont;
                  display: inline-block;
                }
                & img {
                  position: absolute;
                  right: -16px;
                  top: 4px;
                  cursor: pointer;
                  padding: 4px;
                }
              }
            }
            .directOrderListTableContent {
              padding-bottom: 200px;
              .directOrderListItem {
                border-bottom: 1px solid $gray10;
                display: block;

                .directOrderListTableRow {
                  padding: 18px 16px;

                  .directOrderContentWrapper {
                    position: relative;
                    .directOrderUserImg {
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 28px;
                      height: 28px;
                      object-fit: cover;
                    }

                    .customerName {
                      font-weight: $mediumFont;
                      color: $primaryBlue;
                      display: inline-block;
                      margin-left: 40px;
                      word-break: break-word;
                      margin-top: 3px;
                      padding-right: 10px;
                      padding-bottom: 5px;
                    }
                    .customerEmail {
                      color: $gray50;
                      padding-top: 3px;
                      font-size: $font12;
                      word-break: break-word;
                    }

                    .customerDetails {
                      color: $gray90;
                      padding-top: 3px;
                      font-weight: $mediumFont;
                      word-break: break-word;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .directOrderDetails {
    padding: 60px 0px 0px 240px;
    .backBtnWrapper {
      padding: 16px 24px;
      border-bottom: 1px solid $gray10;
    }
    .customerDetails {
      background: $white;
      padding: 32px 24px;
      .customerDetailsNameWrapper {
        position: relative;
        & img {
          width: 42px;
          height: 42px;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .customerName {
          padding-left: 62px;
          color: $primaryBlue;
        }
      }
      .customerEmailWrapper {
        padding-top: 3px;
        .customerEmail {
          line-height: $lineHeight21;
          display: inline-block;
          color: $gray50;
        }
        & span {
          color: $gray90;
          & img {
            vertical-align: middle;
            margin-left: 24px;
            padding-right: 10px;
          }
        }
      }
      .customerPhoneWrapper {
        padding-top: 3px;
        .customerPhone {
          line-height: $lineHeight21;
          display: inline-block;
          color: $gray50;
        }
        & span {
          color: $gray90;
          & img {
            vertical-align: middle;
            margin-left: 24px;
            padding-right: 10px;
          }
        }
      }
    }
    .directOrderDetailsCardWrapper {
      padding: 24px;
      .directOrderDetailsCardBlk {
        .directOrderDetailsCard {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;
          padding: 16px;
          position: relative;
          .directOrderCardWrapper {
            position: relative;
            min-height: 48px;
            & img {
              position: absolute;
              top: 0;
              left: 0;
              width: 48px;
              height: 48px;
              object-fit: cover;
            }
            .directOrderCardContent {
              margin-left: 72px;
              padding-right: 24px;
              & p {
                color: $gray50;
                margin-bottom: 4px;
              }
              & span {
                color: $primaryBlue;
              }
            }
          }
          .directOrderCardArrow {
            & img {
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}
