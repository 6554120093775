@import "../variable";
body {
    .enquiriesPage{
        background: linear-gradient(180deg, #F7FBF9 0%, #ECF3F8 100%);
        padding: 60px 0px 0px 240px;
        .enquiriesHeading{
            border-bottom: 1px solid $gray10;
            .enquiriesTitle{
                color: $gray100;
                padding: 16px 24px;
            }
        }
        .enquiriesSearchBlk{
            padding: 16px 24px;
            .enquiriesSearchInput{
                position: relative;
                width: 164px;
                margin-left: auto;
                & input {
                background: $white;
                border: none;
                height: 32px;
                padding: 14px 8px;
                font-size: $font12;
                }
                & :global(.inputGroup) {
                    margin-bottom: 0;
                }
                & img {
                object-fit: cover;
                position: absolute;
                right: 6px;
                top: 7px;
                }
            }
        }
        .enquiriesListWrapper{
            padding: 0px 24px 24px;
            .enquiriesListInnerBlk{
                background: $white;
                box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
                border-radius: 6px;
                .enquiriesListTableTitle{
                    padding: 16px 16px;
                    border-bottom: 1px solid $gray10;
                    .enquiriesTableTitleTextWrapper{
                        display: flex;
                        justify-content: flex-end;
                        &:first-child {
                        justify-content: flex-start;
                        }
                        .enquiriesTableTitleText{
                            font-size: $font12;
                            color: $gray50;
                        }
                        & img{
                            padding-left: 4px;
                        }
                    }
                }
                .enquiriesListTableContent {
                    .enquiryListItem {
                        border-bottom: 1px solid $gray10;
                        display: block;
                        &:last-child {
                            border-bottom: none;
                        }
                        .enquiryListTableRow {
                            padding:16px;
                            .enquiryContentWrapper {
                                display: flex;
                                align-items: center;
                                .enquiryImageWrapper{
                                    position: relative;
                                    .enquiryBox{
                                    padding: 8px;
                                    border-radius: 4px;
                                    border: 1px solid #E6E8E9;

                                    }
                                    .enquiryBoxStatus{
                                        position: absolute;
                                        right: 0;
                                        bottom: 0;
                                    }
                                }
                                .enquiryDetailWrapper{
                                    padding-left: 12px;
                                    .enquiryNumber{
                                        color: $gray90;
                                        font-weight: $mediumFont;
                                    }
                                }
                                .enquiryDate {
                                    color: $gray90;
                                }
                                .enquiryStatusOpen {
                                    font-size: $font12;
                                    color: $status_orange;
                                    padding-left: 4px;
                                    font-weight: $mediumFont;
                                }
                                .enquiryStatusClose {
                                    font-size: $font12;
                                    color: $color_success_text;
                                    padding-left: 4px;
                                    font-weight: $mediumFont;
                                }
                                .contactEmail {
                                    color: $gray90;
                                    font-weight: $mediumFont;
                                }
                                .contactNumber {
                                    color: $gray100;
                                    font-weight: $mediumFont;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    & :global(.MuiDrawer-paper){
        .enquiryDrawerDetailWrapper{
            background-color: $white;
            width: 520px;
            .enquiryDrawerDetailTitle{
                background-color: $background9;
                display: flex;
                align-items: center;
                padding: 16px 24px;
                .enquiryDrawerDetailHeader{
                    color:$gray90;
                    padding-left: 12px;
                }
                & img{
                    cursor: pointer;
                }
            }
            .enquiryDetailWrapper{
                padding: 24px 16px;
                height: calc(100vh - 150px);
                overflow-y: auto;
                overflow-x: hidden;
                .enquiryDetailBlk{
                    padding: 16px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .enquiryLeftBlk{
                        display: flex;
                        align-items: center;
                        .enquiryImageWrapper{
                            position: relative;
                            .enquiryBox{
                                padding: 8px;
                                border-radius: 4px;
                                border: 1px solid #E6E8E9;
                            }
                            .enquiryBoxStatus{
                                position: absolute;
                                bottom: 0;
                                right: 0;
                            }
                        }
                        .enquiryNumberWrapper{
                            padding-left: 16px;
                            .enquiryDrawerDetailHeader{
                                color: $gray50;
                                font-weight: $regularFont;;
                                padding-bottom: 8px;
                            }
                            .enquiryNumber{
                                color: $gray90;
                                font-weight: $mediumFont;
                            }
                        }
                    }
                    .enquiryRightBlk{
                        .enquiryStatusOpenTitle{
                            color: $gray50;
                            font-weight: $regularFont;
                            padding-bottom: 8px;
                        }
                        .enquiryRightStatusWrapper{
                            display: flex;
                            align-items: center;
                            & img{
                                padding-right: 4px;
                            }
                            .enquiryStatusOpen{
                                color: $status_orange;
                            }
                            .enquiryStatusClose{
                                color: $color_success_text;
                            }
                        }
                    }
                }
                .enquiryDetailTitles{
                    font-size: $font12;
                    color: $gray50;
                }
                .enquiryDetailDescriptions{
                    font-size: $font12;
                    font-weight: $mediumFont;
                    color: $gray100;
                    word-break: break-word;
                }
                .enquiryNoteWrapper{
                    padding: 32px 16px;
                    .enquiryNoteTitle{
                        color: $gray50;
                        padding-bottom: 8px;
                    }
                    .enquiryNoteDetail{
                        color: $gray100;
                        .enquiryNoteMessage{
                            padding: 16px 0;
                        }
                    }
                }
            }
            .enquiryButtonWrapper{
                position: absolute;
                padding: 12px 16px;
                bottom: 0;
                width: 100%;
                text-align: right;
                background-color: $background8;
                border-top: 1px solid $gray10;
                .generateEnquiryBtn{
                    padding: 14px 44px;
                }
            }
        }
    }
}