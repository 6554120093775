@import "variable";

body {
  background: $background6;
  @include fontStyle(
    $fontFamily: $font_Inter,
    $size: $font14,
    $weight: $regularFont,
    $color: $primaryBlue,
    $lineHeight: $lineHeight21
  );

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
      cursor: pointer;
      outline: 0;
    }
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  img {
    max-width: 100%;
  }

  .link-hover {
    color: $white;
  }

  .flexCentered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    align-items: center;
    &:before,
    &:after {
      width: 0;
    }
  }
  .flexSpaceBetween {
    justify-content: space-between;
  }

  .flexNotCentered {
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,
    &:after {
      width: 0;
    }
  }

  .backgroundProp {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .hidden {
    display: none !important;
  }
  label {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $regularFont,
      $color: $gray70,
      $lineHeight: $lineHeight21
    );
    transform: none;
    position: relative;
    margin-bottom: 3px;
    display: block;
    .MuiFormLabel-asterisk {
      color: $red;
    }
  }
  .heading1 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font64,
      $weight: $boldFont,
      $color: $white,
      $lineHeight: $lineHeight96
    );
  }

  .heading2 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font52,
      $weight: $boldFont,
      $color: $white,
      $lineHeight: $lineHeight78
    );
  }

  .heading3 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font48,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight72
    );
  }

  .heading4 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font36,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight54
    );
  }

  .heading5 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font28,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight42
    );
  }

  .heading6 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font24,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight42
    );
  }

  .subheading {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font18,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight27
    );
  }
  .subheading2 {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font16,
      $weight: $semiBoldFont,
      $color: $blue,
      $lineHeight: $lineHeight24
    );
  }

  .paragraph {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $regularFont,
      $color: $gray40,
      $lineHeight: $lineHeight21
    );
    &.italic {
      font-style: italic;
    }
  }
  .paragraphSemiBold {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $semiBoldFont,
      $color: $primaryBlue,
      $lineHeight: $lineHeight21
    );
    &.italic {
      font-style: italic;
    }
  }

  .supportText {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $mediumFont,
      $color: $gray90,
      $lineHeight: $lineHeight18
    );
  }

  .supportTextSemibold {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $semiBoldFont,
      $color: $primaryBlue,
      $lineHeight: $lineHeight18
    );
  }

  .label {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $mediumFont,
      $color: $gray70,
      $lineHeight: $lineHeight21
    );
    cursor: default;
  }

  .avatar {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $semiBoldFont,
      $color: $white,
      $lineHeight: $lineHeight24
    );
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
    background: $primaryBlue;
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
  }

  .primaryBtn {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $mediumFont,
      $color: $white,
      $lineHeight: $lineHeight21
    );
    background: $blue;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 10px 16px;
    outline: none;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    width: max-content;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $blue2;
    }
    &.disabledBtn {
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    }
    &.smallBtn {
      padding: 8px 18px;
      font-size: $font12;
    }
    &.extraSmallBtn {
      padding: 10px 18px;
      font-size: $font10;
      line-height: $lineHeight12;
    }
    &.submitBtn {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .externalLink {
    color: $white;
    font-weight: $mediumFont;
  }

  .secondaryBtn {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $mediumFont,
      $color: $white,
      $lineHeight: $lineHeight21
    );
    background: $bg_red;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 14px 16px;
    outline: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 8px;
    text-transform: capitalize;
    width: max-content;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      background: $bg_red2;
      outline: none;
    }
    &.disabledBtn {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
    &.smallBtn {
      padding: 8px 18px;
      font-size: $font12;
    }
    &.extraSmallBtn {
      padding: 10px 18px;
      font-size: $font10;
      line-height: $lineHeight12;
    }
    &.submitBtn {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .tertiaryBtn {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $mediumFont,
      $color: $gray70,
      $lineHeight: $lineHeight21
    );
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 8px 16px;
    outline: none;
    text-align: center;
    display: inline-block;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    text-transform: capitalize;
    background: transparent;
    width: max-content;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $background1;
    }
    &.disabledBtn {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .outlinedBtn {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $mediumFont,
      $color: $blue,
      $lineHeight: $lineHeight21
    );
    background: $white;
    border: 1px solid $blue;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 14px 16px;
    outline: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    width: max-content;
    &:hover,
    &:focus,
    &:active {
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
      outline: none;
      background: $background1;
    }
    &.disabledBtn {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
    &.smallBtn {
      padding: 8px 18px;
      font-size: $font12;
    }
    &.extraSmallBtn {
      padding: 10px 18px;
      font-size: $font10;
      line-height: $lineHeight12;
    }
    &.submitBtn {
      width: 100%;
      box-sizing: border-box;
    }
    &.dangerBtn {
      border: 1px solid $bg_red;
      color: $bg_red;
    }
  }

  .linkBtn {
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font14,
      $weight: $mediumFont,
      $color: $blue,
      $lineHeight: $lineHeight21
    );
    background: transparent;
    border-radius: 4px;
    @include transitionForAll($time: 0.4s, $transProp: ease-out);
    padding: 14px 16px;
    text-transform: capitalize;
    width: max-content;
    &:hover,
    &:focus,
    &:active {
      background: $background1;
      @include transitionForAll($time: 0.3s, $transProp: ease-in);
    }
    &.disabledBtn {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
    }
    &.dangerBtn {
      color: $bg_red;
    }
  }

  .errorMsg {
    position: relative;
    @include fontStyle(
      $fontFamily: $font_Inter,
      $size: $font12,
      $weight: $regularFont,
      $color: $red !important,
      $lineHeight: $lineHeight18
    );
    padding-top: 4px;
    // padding-left: 20px;
    // &::before {
    //   content: '';
    //   background: url('https://stb-objects.sfo3.digitaloceanspaces.com/static/assets/img/error_icon.svg') no-repeat;
    //   width: 15px;
    //   height: 14px;
    //   position: absolute;
    //   background-size: contain;
    //   top: 5px;
    //   left: 2px;
    // }

    &.absolute {
      position: absolute;
      margin-left: 12px;
    }
  }

  .mr8 {
    margin-right: 8px;
  }
  .mr16 {
    margin-right: 16px;
  }
  .mr128 {
    margin-right: 128px;
  }

  .ml8 {
    margin-left: 8px;
  }
  .ml16 {
    margin-left: 16px;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  .flex-item {
    /* this */
    flex: 1 100px;
    /* is the same as */
    flex-grow: 1;
    flex-basis: 100px;
    /* and it leaves the flex-shrink property alone, which would be */
    flex-shrink: inherit; /* defaults to 1 */
  }

  .listUnstyled {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .listInline {
    list-style: none;
    .listInlineItem {
      display: inline-block;
      width: auto;
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .listInlineAway {
    list-style: none;
    display: flex;
    justify-content: space-between;
    .listInlineItem {
      display: inline-block;
      width: auto;
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
  .MuiLink-root {
    text-decoration: none;
    font-weight: $mediumFont;
    color: $blue;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .textCenter {
    text-align: center;
  }

  .textRight {
    text-align: right;
  }

  *:focus {
    outline: 0;
    outline: none;
  }

  .MuiFormLabel-asterisk {
    color: $gray1;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }

  .inputGroup {
    outline: none;
    margin-bottom: 20px;
    &.noMargin {
      margin-bottom: 0px;
    }
    // position: relative;

    &.errorGroup {
      input,
      textarea,
      select {
        // border: 1px solid $red;
        background: $color_error_bg;
        &:focus {
          // background: transparent;
        }
      }
    }
    &.couponGroup {
      input {
        border: 1px solid $gray10;
        background: $white;
        &:focus {
          background: $background1;
          border: 1px solid $blue;
        }
      }
    }
    .errorGroup {
      input,
      textarea,
      select {
        border: 1px solid $color_error_text;
        background: $color_error_bg;
        &:focus {
          // background: transparent;
        }
      }
    }
    .disabledInputGroup {
      cursor: not-allowed;
      pointer-events: none;
      input,
      textarea,
      select {
        background: $gray1;
      }
    }
    .commentInputGroup {
      input {
        border: none;
        margin-bottom: 0;
        height: 32px;
        &:focus {
          border: none;
        }
      }
    }
    &.selectGroup {
      .inputGroup {
        margin-bottom: 0;
      }
      &.errorGroup {
        .reactSelect > div {
          border: 1px solid $red;
          // background: $lightRed;
          &:focus {
            background: transparent;
          }
        }
      }
    }
    label {
      @include fontStyle(
        $fontFamily: $font_Inter,
        $size: $font12,
        $weight: $regularFont,
        $color: $gray70,
        $lineHeight: $lineHeight21
      );
      transform: none;
      position: relative;
      margin-bottom: 3px;
      display: block;
      .MuiFormLabel-asterisk {
        color: $red;
      }
    }
    input,
    select {
      height: 48px;
    }
    .MuiFormControl-root {
      margin: 0;
      width: 100%;
    }
    input,
    textarea,
    select {
      width: 100%;
      @include fontStyle(
        $fontFamily: $font_Inter,
        $size: $font14,
        $weight: $regularFont,
        $color: $primaryBlue,
        $lineHeight: $lineHeight21
      );
      background: $white;
      border-radius: 8px;
      border: 1px solid $gray10;
      padding: 14px 16px;
      box-sizing: border-box;
      &:active,
      &:focus {
        background: $background1;
        border: 1px solid $blue;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray40,
          $lineHeight: $lineHeight21
        );
        opacity: 1;
        text-transform: none;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray40,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray40,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray40,
          $lineHeight: $lineHeight21
        );
        text-transform: none;
      }
      .MuiTypography-body1 {
        background: none;
        padding-left: 0 !important;
        font-size: $font16;
      }
      &.Mui-disabled {
        border: 1px solid $background2;
        background: $background2;
        opacity: 0.7;
        pointer-events: none;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
    .MuiSelect-select {
      background: $white;
      border: 1px solid $gray10;
      border-radius: 8px;
      padding: 14px 16px;
      @include fontStyle(
        $fontFamily: $font_Inter,
        $size: $font14,
        $weight: $mediumFont,
        $color: $primaryBlue,
        $lineHeight: $lineHeight21
      );
    }
    .MuiFormLabel-root.Mui-focused {
      // color: $primaryRed;
      color: rgba(0, 0, 0, 0.9);
    }
    label + .MuiInput-formControl {
      margin-top: 0px;
    }
    .MuiOutlinedInput-notchedOutline {
      display: none;
    }
    .MuiInput-underline.Mui-disabled:before {
      border-bottom: none;
    }
    .MuiSelect-select.Mui-disabled {
      background: $white;
      opacity: 0.8;
      cursor: text;
      color: rgba(0, 0, 0, 0.6);
    }

    .MuiInput-underline:before {
      border-bottom: none;
    }
    .MuiInput-underline:after {
      border-bottom: none;
    }
    .MuiInputBase-multiline {
      padding: 0;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    .MuiInput-formControl {
      // margin-top: 0;
    }
    .selectFormControl {
      .MuiSelect-icon {
        right: 16px;
      }
    }

    .MuiAutocomplete-input {
      padding: 8px;
    }

    // .reactSelect {
    //   // margin-top: 8px;
    //   > div {
    //     cursor: pointer;
    //     border-color: $gray10;
    //     height: 48px;
    //     min-height: 48px;
    //     background: $white;
    //     border-radius: 8px;
    //     // margin-right: 10px;
    //     &:focus {
    //       background: $background1;
    //       border: 1px solid $blue;
    //     }
    //   }
    //   input {
    //     height: 26px;
    //     box-shadow: none;
    //   }
    //   .css-1fhf3k1-control {
    //     background: $white;
    //     opacity: 0.6;
    //     .css-g1d714-ValueContainer {
    //       .css-107lb6w-singleValue {
    //         cursor: text;
    //         color: rgba(0, 0, 0, 0.8);
    //         font-weight: $regularFont;
    //       }
    //     }
    //   }
    //   .css-26l3qy-menu {
    //     z-index: 99999999;
    //     @include fontStyle(
    //       $fontFamily: $font_Inter,
    //       $size: $font14,
    //       $weight: $regularFont,
    //       $color: $gray100,
    //       $lineHeight: $lineHeight21
    //     );
    //     box-shadow: none;
    //     border: none;
    //     > div {
    //       background: $white;
    //       border-radius: 8px;
    //       border: 1px solid $background2;
    //       box-shadow: 0px 2px 8px $gray1;
    //     }
    //   }
    //   .css-1pahdxg-control {
    //     // border-color: $primaryRed;
    //     box-shadow: 0px 0px 10px rgba(0, 210, 173, 0.36);
    //   }
    //   > div {
    //     // width: auto !important;
    //   }
    //   .css-g1d714-ValueContainer {
    //     padding: 0 8px;
    //     // height: 42px;
    //     .css-1uccc91-singleValue {
    //       @include fontStyle($weight: $semiBoldFont);
    //     }
    //     .css-1wa3eu0-placeholder {
    //       font-size: $font14;
    //       font-family: $font_Inter;
    //       color: $gray40;
    //     }
    //   }
    //   .css-xb97g8 {
    //     svg {
    //       height: 16px;
    //       fill: #c7d2e0;
    //     }
    //   }
    //   .css-xb97g8:hover {
    //     background: #f1f4f8;
    //     cursor: pointer;
    //     &:focus {
    //       outline: none;
    //       box-shadow: none;
    //     }
    //   }
    //   .css-12jo7m5 {
    //     img {
    //       display: none !important;
    //       opacity: 0 !important;
    //     }
    //     .contentBlk {
    //       margin-left: 40px !important;
    //       p {
    //         display: none !important;
    //         opacity: 0 !important;
    //       }
    //     }
    //   }
    //   .css-1wa3eu0-placeholder {
    //     @include fontStyle(
    //       $fontFamily: $font_Inter,
    //       $size: $font14,
    //       $weight: $semiBoldFont,
    //       $color: $gray100,
    //       $lineHeight: $lineHeight21
    //     );
    //     opacity: 1;
    //     text-transform: none;
    //   }

    //   /* .css-myyece-control{
    //     &:active,
    //     &:focus{
    //       background: $background1 !important;
    //       border: 1px solid $blue !important;
    //     }
    //   } */
    // }
  }

  .phoneNumberGroup {
    label {
      margin-bottom: 3px !important;
    }
    .PhoneInput {
      border: 1px solid $gray10;
      border-radius: 8px;
      padding-left: 10px;
      input {
        border: none;
        &:focus {
          /* box-shadow: none; */
        }
      }
    }
    .PhoneInput--focus {
      background: $background1;
      border: 1px solid $blue;
    }
    .PhoneInputCountrySelect {
      pointer-events: none;
    }
    .PhoneInputCountrySelectArrow {
      display: none;
    }
  }

  .MuiMenu-list {
    li {
      @include fontStyle(14px, $semiBoldFont, $gray100);
      background: $white;
      &.deleteMenuItem {
        span {
          color: $red;
        }
      }
    }
  }
  .MuiListItem-root.Mui-selected {
    // background: rgba(239, 67, 73, 0.1);
    // background: #f4f2ef;
    // color: $gray1;
    &:hover {
      // background: #f4f2ef;
    }
  }
  .MuiListItem-button:hover {
    // background: #f4f2ef;
  }

  .textAreaGroup {
    margin-bottom: 16px;
    textarea {
      max-width: 100%;
      width: 100% !important;
      // height: 100% !important;
    }
  }

  .MuiRadio-root {
    //color: $blue;
    font-weight: $semiBoldFont;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: $blue;
    font-weight: $semiBoldFont;
  }
  .radioButton {
    .MuiTypography-root {
      @include fontStyle(
        $fontFamily: $font_Inter,
        $size: $font14,
        $weight: $mediumFont,
        $color: $background2,
        $lineHeight: $lineHeight21
      );
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: $blue;
    }
    .MuiRadio-colorSecondary.Mui-checked:hover {
      background: transparent;
    }
  }

  .passwordGroup {
    position: relative;
    .passwordShow {
      position: absolute;
      top: 39px;
      right: 14px;
      z-index: 99;
      cursor: pointer;
      // color: $color_2F80ED;
      text-transform: uppercase;
    }
    input {
      padding-right: 55px;
    }
    .MuiTooltip-popper {
      // transform: translate3d(0, -136px, 0px) !important;
      transform: translate3d(198px, -46px, 0px) !important;
      right: 0 !important;
      left: auto !important;
    }
  }
  .transparentTextfield {
    input {
      background-color: transparent;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
  .checkboxGroup {
    margin-bottom: 16px;
    label {
      margin-bottom: 0px;
      margin-left: 0;
      margin-right: 6px;
      .MuiIconButton-label {
        color: $blue;
      }
      .MuiTypography-root {
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font14,
          $weight: $regularFont,
          $color: $gray50,
          $lineHeight: $lineHeight21
        );
        position: relative;
        top: 3px;
      }
      .MuiButtonBase-root {
        padding: 4px;
      }
      input {
        height: 24px;
      }
      .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
      }
    }
  }

  .MuiSelect-select:focus {
    background: $white;
  }

  .MuiTooltip-tooltip {
    // padding: 0 !important;
    // padding: 16px;
    // background: $gray2;
    // color: $white;
  }

  .MuiTooltip-popper {
    z-index: 99999 !important;
  }

  .moreMenu {
    z-index: 9999 !important;
    .MuiPaper-elevation8 {
      box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06);
    }
    &.moreMenuHoirzontal {
      padding: 0px 8px;
    }

    .deleteMenuItem {
      span {
        color: red;
      }
    }
  }

  .MuiPopover-root {
    z-index: 99999 !important;
  }

  .redColorFont {
    color: $red !important;
  }

  .customRadioButton {
    a {
      background: transparent;
      border: 1px solid $gray1;
      border-radius: 4px;
      padding: 10px 20px 8px;
      @include transitionForAll($time: 0.3s, $transProp: ease);
    }
    &.active,
    &:hover {
      a {
        // background: $lightAccent;
        // border: 1px solid $blue;
        // span {
        //   color: $blue;
        // }
      }
    }
    &.multi {
      a {
        padding-right: 40px;
        position: relative;
        span,
        img {
          vertical-align: middle;
        }
        img {
          // margin-left: 16px;
          position: absolute;
          right: 15px;
          width: 11px;
          height: 11px;
          top: 15px;
        }
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    // margin: 0;
  }

  input {
    &.search {
      background-image: url("");
      background-position: left 8px center;
      background-size: 18px;
      background-repeat: no-repeat;
      height: 30px;
      padding: 5px;
      padding-left: 32px;
    }
  }

  .switchWrapper {
    .MuiFormControlLabel-root {
      margin-right: 0;
      .MuiSwitch-track {
        height: 16px;
        width: 30px;
        border-radius: 14px;
      }
      .MuiSwitch-thumb {
        height: 12px;
        width: 12px;
        position: relative;
        left: 5px;
        top: 3px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background: $blue;
        opacity: 1;
        border-radius: 14px;
      }
      .MuiSwitch-colorSecondary.Mui-checked {
        color: $white;
        left: -5px;
      }
      .MuiButtonBase-root {
        height: 16px;
        &:hover {
          background: none;
        }
      }
    }
  }

  .MuiPopover-paper {
    border-radius: 12px;
  }

  .slick-dots {
    bottom: -50px;
  }

  .slick-dots li button:before {
    font-size: 16px;
    color: $gray10;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: $bg_red;
    opacity: 1;
  }

  .slick-prev,
  .slick-next {
    width: 90px;
    height: 90px;
    z-index: 10;
  }

  .slick-prev {
    left: 60px;
    background-image: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/slick-left-button.svg) !important;
  }

  .slick-next {
    right: 60px;
    background-image: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/slick-right-button.svg) !important;
  }

  .slick-prev::before,
  .slick-next::after {
    content: "";
  }

  .customDatePicker {
    .MuiInputBase-root {
      background: white;
      border-radius: 8px;
      border: 1px solid $gray10;
      cursor: pointer;
      &:focus {
        background: red;
      }
      input {
        border: none;
        background: transparent;
        cursor: pointer;
      }
      .MuiButtonBase-root {
        background: transparent;
        .MuiIconButton-label {
          position: relative;
          right: 10px;
        }
      }
    }
  }
  .customPagination {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
  }
  .MuiPaginationItem-rounded {
    border-radius: 0;
    margin: 0;
    background-color: $white !important;
    border: 1px solid $gray10;
  }
  .MuiPaginationItem-root {
    font-family: $font_Inter;
    font-size: $font12;
    line-height: $lineHeight21;
    font-weight: $regularFont;
    color: $gray50;
  }
  .MuiPaginationItem-page.Mui-selected {
    color: $blue;
  }
  .registerOutlinedBtn {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4361ee;
    line-height: 21px;
    background: #ffffff;
    border: 1px solid #4361ee;
    padding: 10px 10px;
    outline: none;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    text-transform: capitalize;
    width: max-content;
    width: 100%;
    margin-top: 10px;
}
}
