@import "variable";
body {
  .usersPage {
    // background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
    padding: 114px 0px 0px 240px;
    .usersListWrapper {
      .usersHeading {
        .userTitle {
          color: $gray100;
          padding: 16px 24px;
          .usersCount {
            color: $gray90;
            font-size: $font12;
          }
        }
        border-bottom: 1px solid $gray10;
      }
      .usersListStatus {
        .userFilter {
          display: flex;
          padding: 0;
          margin: 0 24px;
          border-bottom: 1px solid $gray10;
          .filterItem {
            width: auto;
            padding: 16px;
            color: $gray90;
            font-weight: $mediumFont;
            border-bottom: 2px solid $blue;
            cursor: pointer;
          }
        }
      }
      .userSearchBlk {
        padding: 16px 0;
        margin: 0 24px;
        .userSearchWrapper {
          display: flex;
          gap: 16px;
          justify-content: flex-end;
          .userSearchInput {
            position: relative;
            width: 160px;
            & input {
              background: $white;
              border: none;
              height: 32px;
              border-radius: 4px;
              padding: 14px 8px;
              font-size: $font12;
            }
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
          .addUser {
            .inviteButton {
              position: relative;
              padding: 5px 24px 5px 48px;
              border-radius: 4px;
              & img {
                position: absolute;
                top: 8px;
                left: 24px;
              }
            }
          }
        }
      }
      .usersList {
        padding: 0px 24px 24px;
        .usersListInnerBlk {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;
          .usersListTableTitle {
            padding: 16px 16px;
            border-bottom: 1px solid $gray10;
            .usersTableTitleTextWrapper {
              display: flex;
              justify-content: flex-end;
              &:first-child {
                justify-content: flex-start;
              }
              .usersTableTitleText {
                font-size: $font12;
                color: $gray50;
              }
              & img {
                cursor: pointer;
                padding-left: 4px;
              }
            }
          }
          .usersListTableContent {
            .userListItem {
              border-bottom: 1px solid $gray10;
              display: block;
              &:last-child {
                border-bottom: none;
              }
              .usersListTableRow {
                padding: 16px;
                .userContentWrapper {
                  display: flex;
                  .userImageWrapper {
                    position: relative;
                    .userBox {
                      padding: 8px;
                      width: 50px;
                      height: 50px;
                      border-radius: 50%;
                    }
                  }
                  .userDetailWrapper {
                    padding-left: 12px;
                    .userName {
                      font-weight: $mediumFont;
                    }
                    .userEmail {
                      color: $gray50;
                      font-size: $font12;
                      font-weight: $regularFont;
                    }
                  }
                  .lastActive,
                  .joinedDate {
                    color: $gray50;
                    font-size: $font12;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .userRole,
                  .assignedWorkspace {
                    color: $gray90;
                    font-size: $font12;
                    font-weight: $mediumFont;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .userDialogWrapper {
    .userDialogTitle {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid $gray10;
      .userDialogHeader {
        color: $gray100;
      }
    }
    .userDialogDetail {
      padding: 24px 0 0 0;
      margin: 0 32px;
      border-bottom: 1px solid $gray10;
      .userDialogInput {
        display: flex;
        position: relative;
        .userInputEmail {
          & input {
            width: 300px;
            border-radius: 8px 0 0 8px;
          }
        }
        .userInputOrg {
          & :global(.css-myyece-control) {
            width: 200px;
            height: 48px;
            border: 1px solid $gray10;
            background-color: $white;
            border-radius: 0 8px 8px 0;
            & :active {
              width: 200px;
              height: 48px;
              border: 1px solid $blue;
            }
          }
          & :global(.css-4n0wjs-control) {
            width: 200px;
            height: 48px;
            border: 1px solid $blue;
            background-color: $background1;
            border-radius: 0 8px 8px 0;
          }
          width: 200px;
          position: relative;
          top: -1px;
          /* & :global(.css-4n0wjs-control){
                        width: 200px;
                        border: 1px solid #343334;
                    }
                    & :global(.css-myyece-control){
                        height: 48px;
                        border-radius: 0 8px 8px 0;
                        background-color: $white;
                    } */
        }
      }
      .userDialogDropdown {
        & :global(.css-26l3qy-menu) {
          width: 300px;
          border-radius: 4px;
          & input {
            border-radius: 4px;
          }
          > div {
            border-radius: 4px;
          }
        }
      }
    }
    .removeUsersBtn {
      position: absolute;
      height: 16px;
      right: -20px;
      top: 15px;
      cursor: pointer;
    }
    .userDialogButtonWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 36px;
      .userButtonAddMore {
        color: $blue;
        font-weight: $mediumFont;
        position: relative;
        margin: 16px 0 48px 0;
        & img {
          position: absolute;
          top: 4px;
          left: -24px;
        }
      }
      .inviteBtn {
        width: 115px;
        border-radius: 4px;
      }
    }
  }
}
