@import "variable";

body {
  .heroSection {
    text-align: center;
    background: linear-gradient(191.37deg, #f43044 -186.79%, #011627 61.52%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
    p {
      color: $white;
    }
    .backgroundPadding {
      padding-top: 90px;
      .background {
        background-image: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/background_abstract.svg);
        background-position-y: bottom;
        padding-bottom: 140px;
        .heading {
          padding-bottom: 60px;
          .heroTitle {
            font-weight: $boldFont;
          }
          .subheading {
            font-weight: $regularFont;
            color: $white;
            padding-top: 30px;
          }
        }
      }
    }
  }

  .placeOrder {
    margin: 40px 0 120px 0;

    .pricingSection {
      padding-top: 50px;
      .pricingLabel {
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font32,
          $weight: $boldFont,
          $color: $gray100,
          $lineHeight: $lineHeight36
        );
      }
      .PricingSubText {
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font10,
          $weight: $regularFont,
          $color: $gray50,
          $lineHeight: $lineHeight12
        );
      }
      .PricingOneTime {
        padding-bottom: 24px;
        color: $gray80;
      }
      .pricingBtn {
        padding: 14px 36px;
      }
      .PricingTag {
        padding: 8px 0;
        color: $gray50;
      }
      .textLink {
        text-align: start;
        @include fontStyle(
          $fontFamily: $font_Inter,
          $size: $font16,
          $weight: $regularFont,
          $color: $gray100,
          $lineHeight: $lineHeight21
        );
        & a {
          padding-left: 12px;
        }
        & img {
          position: relative;
          top: 5px;
        }
      }
    }

    .partnerWrapper {
      position: relative;
      background: #fff;
      margin-top: 100px;
      padding: 40px;
      border-radius: 10px;

      .partnerHeading {
        color: $gray100;
        line-height: $lineHeight36;
      }

      .partnerSubHeading {
        margin-top: 8px;
        color: $gray80;
      }

      .buttonWrapper {
        display: flex;
        justify-content: flex-end;
      }

      .partnerButton {
        padding: 14px 63px;
        height: 48px;
        border: 1px solid $blue;
        border-radius: 8px;
      }

      .partnerBanner {
        position: absolute;
        top: 0;
        right: 0;
        padding: 2px 8px;
        background-color: #2e9940;
        color: $white;
        border-radius: 0 8px 0 8px;
      }
    }
  }

  .videoSection {
    display: none;
    .videoWrapper {
      position: relative;
      margin: 80px auto 140px auto;
      .playIcon {
        position: absolute;
        top: 40%;
        left: 50%;
        z-index: 99;
        transform: translateX(-50%, -50%);
        cursor: pointer;
        &.playIconWebsite {
          top: 50%;
        }
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        border: 1px solid #17171d;
        padding: 30px;
        border-radius: 20px;
        background: #17171d;
      }
      &.mobile {
        width: 404px;
        video {
          left: 55px;
          width: 294px;
          top: -9px;
          border: 1px solid #d0d0d0;
          padding: 30px;
          border-radius: 20px;
          background-color: #e2e2df;
          min-height: 590px;
        }
      }
    }
  }

  .getSection {
    background: linear-gradient(264.17deg, #251a2b 2.72%, #011627 75.71%);
    clip-path: polygon(0 0, 100% 7%, 100% 100%, 0 93%);
    padding: 160px 0 220px 0;

    .getTitle {
    }
    .card {
      margin-top: 72px;
      .cardTitle {
        margin: 20px 0 16px 0;
      }
    }
  }

  .Carousel {
    margin-bottom: 120px;
    .carouselTitle {
      text-align: center;
      padding: 100px 0 32px 0;
      @include fontStyle(
        $fontFamily: $font_Inter,
        $size: $font24,
        $weight: $semiBoldFont,
        $color: $gray100,
        $lineHeight: $lineHeight36
      );
    }

    .carouselCard {
      margin: 0 auto;
      background-color: $white;
      border-radius: 16px;
      .CardItem {
        padding: 120px 0 80px 0;
        .founderImg {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          border: 5px solid $white;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          object-fit: cover;
        }
        .profileName {
          color: $gray100;
        }
        .userVoice {
          margin-top: 40px;
          color: $gray70;
        }
        .userStar {
          margin-top: 60px;
        }
      }
    }
  }

  .BoxSupport {
    text-align: center;
    margin: 75px 0 140px 0;

    .BoxSupportTitle {
      color: $gray100;
      line-height: $lineHeight36;
    }

    .BoxSupportSubTitle {
      margin: 16px 0 32px 0;
      color: $gray70;
    }

    .BoxImage {
      margin: 0 auto 8px auto;
      padding: 20px 52px;
      border-radius: 8px;
      background-color: $white;
    }
  }
}
