@import "variable";

body {
  .partnerPage {
    background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/partner_bg.png);
    background-color: #fff;
    background-size: 100% 160px;
    background-repeat: no-repeat;
    //background: linear-gradient(211.65deg, #F43044 -91.16%, #011627 39.34%);
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
    .partnerPageWrapper {
      .partnerLeftBlk {
        .logoInPartner {
          margin: 8px 0 72px 0;
        }
        .backBtn {
          display: flex;
          font-weight: $mediumFont;
          color: $gray60;
        }
        &Header {
          margin-top: 90px;
          color: $gray100;
          font-weight: $boldFont;
        }
        &SubHeader {
          margin: 16px 0 8px;
          color: $gray70;
        }
        &Text {
          color: $gray70;
          margin-bottom: 60px;
        }
      }
      .partnerRightBlk {
        margin: 0px 0 56px;
        .becomePartnerWrapper {
          background: white;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
          border-radius: 16px;
          margin-top: 84px;
          .PartnerForm {
            padding: 64px 90px 48px;
            .becomePartnerHeading {
              color: $gray100;
              margin-bottom: 30px;
            }
            .registerCheckbox {
              display: flex;
              align-items: center;
              margin: 36px 0 32px;
              .checkCondition {
                color: $gray100;
                margin-left: -8px;
                &Blue {
                  color: $blue;
                  font-weight: $mediumFont;
                }
              }
            }
            .registerBtn {
              width: 100%;
              padding: 14px;
              font-weight: $mediumFont;
            }
          }
        }
        .PartnerSuccessWrapper {
          background: white;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
          border-radius: 16px;
          margin-top: 84px;
          text-align: center;
          .SuccessConfetti {
            margin: 100px 0 32px;
          }
          .SuccessHeading {
            color: $gray100;
            line-height: $lineHeight36;
            padding: 0 90px;
          }
          .SuccessSubHeading {
            color: $gray50;
            margin: 24px 0 36px;
          }
          .backToHomeBtn {
            padding: 14px 78px;
            margin-bottom: 136px;
          }
        }
      }
    }
  }
}
