@import "variable";
body {
  .devicesPage {
    padding: 114px 0px 0px 240px;
    .devicesWrapper {
      .devicesHeaderWrapper {
        .devicesPageTitleWrapper {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
          .devicesPageTitle {
            color: $primaryBlue;
            .devicesCount {
              color: $gray90;
              font-size: $font12;
              padding-left: 2px;
            }
          }
        }
        .devicesTabWrapper {
          .devicesTab {
            //padding: 0px 24px;
            border-bottom: 1px solid $gray10;
            .quotationsListFilter {
              display: flex;
              padding: 0;
              // margin: 0 24px;
              // margin-bottom: 15px;
              // border-bottom: 1px solid $gray10;
              .filterItem {
                width: auto;
                padding: 16px;
                font-family: $font_Inter;
                font-size: $font14;
                font-weight: $mediumFont;
                color: $gray40;
                text-transform: capitalize;
                cursor: pointer;
                border-bottom: 2px solid $background6;
              }
              & :global(.Mui-selected) {
                color: $gray90;
                font-weight: $semiBoldFont;
                border-bottom: 2px solid $blue;
              }
              & :global(.MuiTabs-indicator) {
                display: none;
              }
            }
          }
        }
      }
      .devicesListWrapper {
        padding: 24px;
        .devicesWorkspace {
          color: $gray80;
          font-weight: $mediumFont;
          line-height: $lineHeight21;
          display: inline-block;
          position: relative;
          top: 4px;
          margin-right: 12px;
        }
        .workspaceLocationSelect {
          display: inline-block;
          width: 60%;
          max-width: 180px;
          & :global(.inputGroup) {
            // margin-bottom: 0;
          }
          & :global(.reactSelect > div) {
            border-radius: 6px;
          }
        }
        .deviceSearchBlk {
          .deviceSearchInput {
            position: relative;
            width: 160px;
            float: right;
            & input {
              background: $white;
              border: none;
              height: 32px;
              padding: 14px 8px;
              font-size: $font12;
              border-radius: 4px;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
        }
        .devicesList {
            .deviceTypeTitle {
            margin-top: 7px;
          }
          .devicesListItemWrapper {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;
            .devicesListItemTitleWrapper {
              padding: 16px;
              border-bottom: 1px solid $gray10;
              .devicesListItemTitleTextWrapper {
                position: relative;
                display: inline-block;
                .devicesListItemTitle {
                  line-height: $lineHeight21;
                  color: $gray50;
                  font-weight: $regularFont;
                  display: inline-block;
                }
                & img {
                  top: 0px;
                  right: 2px;
                  //   position: absolute;
                  //   right: -16px;
                  //   top: 4px;
                  //   cursor: pointer;
                  //   padding: 4px;
                }
              }
            }
            .devicesListTableContent {
              margin-bottom: 32px;
              .devicesListTableRow {
                padding: 24px 16px;
                border-bottom: 1px solid $gray10;
                @include transitionForAll($time: 0.3s, $transProp: ease-in);
                &:hover {
                  box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
                }
                &:last-child {
                  // border-bottom: none;
                }
                .devicesContentWrapper {
                  position: relative;
                  .devicePath {
                    display: block;
                    .deviceImg {
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 43px;
                      height: 43px;
                      object-fit: cover;
                    }
                    .devicesStatus {
                      position: absolute;
                      left: 18px;
                      top: 20px;
                      & img {
                        width: 25px;
                        object-fit: cover;
                      }
                    }
                    .deviceName {
                      font-weight: $mediumFont;
                      color: $primaryBlue;
                      display: inline-block;
                      margin-left: 60px;
                      word-break: break-word;
                      margin-top: 10px;
                      padding-bottom: 10px;
                      padding-right: 10px;
                    }
                  }
                  .deviceRoom {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .deviceMacID {
                    color: $gray90;
                    padding-top: 8px;
                    word-break: break-word;
                  }
                  .deviceSubPeriod {
                    color: $gray50;
                    padding-top: 8px;
                    word-break: break-word;
                    & span {
                      color: $gray90;
                      font-weight: $mediumFont;
                    }
                  }
                  .activateBtn {
                    height: 40px;
                    padding: 7px 16px;
                  }
                  & :global(.inputGroup) {
                    margin-bottom: 0;
                    height: 40px;
                  }
                  & :global(.MuiInputBase-input) {
                    width: 75%;
                    height: 40px;
                    border-radius: 4px;
                  }
                  & :global(.css-80k6cp-control) {
                    height: 40px;
                  }
                  & :global(.css-8u5oqz-ValueContainer) {
                    height: 40px;
                  }
                  & :global(.css-zv9he5-IndicatorsContainer) {
                    height: 40px;
                  }
                  .selectRoomInput {
                    width: 75%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .deviceInfoPage {
    padding: 114px 0px 0px 240px;
    .deviceInfoWrapper {
      background: $white;
      .deviceDetailsWrapper {
        .backBtnWrapper {
          padding: 16px 24px;
          border-bottom: 1px solid $gray10;
        }
        .deviceDetailsBlk {
          .deviceDetails {
            background: $background8;
            .deviceDetailsOuterBlk {
              padding: 24px;
              border-bottom: 1px solid $gray10;
              padding-bottom: 32px;
              .deviceDetailsImg {
                position: relative;
                display: inline-block;
                width: 120px;
                height: 120px;
                margin-bottom: 24px;
                .deviceImg {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 120px;
                  height: 120px;
                  object-fit: cover;
                }
                .deviceStatus {
                  position: absolute;
                  right: 10px;
                  bottom: 5px;
                  & img {
                    width: 44px;
                    height: 44px;
                    object-fit: cover;
                  }
                }
              }
              .viewDeviceLog {
                color: $blue;
                font-weight: $mediumFont;
                float: right;
              }
              .deviceDetailsInnerBlk {
                .deviceName {
                  color: $primaryBlue;
                  margin-bottom: 16px;
                }
                .deviceDetailsInnerRow {
                  margin-bottom: 24px;
                  &:last-child {
                    margin-bottom: 0;
                  }
                  .deviceDetailsLeftBlk {
                    .deviceDetailsLeftText {
                      font-weight: $regularFont;
                      line-height: $lineHeight21;
                      color: $gray50;
                    }
                    .deviceDetailsRoomNameText {
                      font-weight: $regularFont;
                      line-height: $lineHeight21;
                      color: $gray50;
                      position: relative;
                      // top: 12px;
                    }
                  }
                  .modal {
                    display: none; /* Hidden by default */
                    position: fixed; /* Stay in place */
                    z-index: 1; /* Sit on top */
                    left: 0;
                    top: 0;
                    width: 100%; /* Full width */
                    height: 100%; /* Full height */
                    overflow: hidden; /* Enable scroll if needed */
                    background-color: rgb(0,0,0); /* Fallback color */
                    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
                  }
                   /* Modal Content */
              .modalContent {
                background-color: #fefefe;
                margin: auto;
                padding: 20px;
                border: 1px solid #888;
                width: 30%;
                margin-top: 300px;
              }
               /* The Close Button */
               .close {
                color: #aaaaaa;
                float: right;
                font-size: 28px;
                font-weight: bold;
              }
              
              .close:hover,
              .close:focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
              }
                  .deviceDetailsRightBlk {
                    .deviceDetailsMacID {
                      font-weight: $regularFont;
                      color: $black;
                    }
                    .deviceDetailsSelectRoom {
                      width: 50%;
                      & :global(.inputGroup) {
                        margin-bottom: 0;
                      }
                    }
                    .deviceStatusSuccess {
                      color: $color_success_text;
                      font-weight: $mediumFont;
                      & img {
                        vertical-align: middle;
                        margin-right: 4px;
                      }
                    }
                    .deviceStatusDeactivate {
                      color: $gray50;
                      font-weight: $mediumFont;
                      & img {
                        vertical-align: middle;
                        margin-right: 4px;
                      }
                    }
                    .deviceDetailsFirmwareVersion {
                      color: $black;
                      & span {
                        font-weight: $mediumFont;
                      }
                    }
                  }
                }
              }
            }
            .appLinkWallpaper{
              display: flex;
              float: right;
              gap: 20px;
              align-items: center;
              .appLinkInnerContainer{
                border: 1px solid lightGray;
                padding: 10px; 
                border-radius: 8px;
                background: #e6e8e9;
              }
              .appLinkIconContainer{
                display: flex;
                gap: 10px;
              }
              .appLinkText{
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 5px;
                font-size: 12px;
                color: blue;
                display: block;
              }

            }
            .deviceSettings {
              padding: 24px;
              .deviceSettingsWrapper {
                .deviceSettingsHeading {
                  .deviceSettingsTitle {
                    margin-top: 8px;
                    color: $gray50;
                    font-weight: $mediumFont;
                    margin-bottom: 32px;
                  }
                }
                .deviceSettingsRow {
                  margin-bottom: 32px;
                  .deviceSettingsLeftBlk {
                    .deviceSettingsLeftTitle {
                      color: $gray3;
                      line-height: $lineHeight21;
                      margin-bottom: 4px;
                    }
                    .deviceSettingsLeftSubText {
                      color: $gray50;
                    }
                  }
                  .deviceSettingsRightBlk {
                    float: right;
                    & img {
                      width: 60px;
                      object-fit: cover;
                      margin-right: 20px;
                      vertical-align: middle;
                    }
                    & video {
                      width: 60px;
                      object-fit: cover;
                      margin-right: 20px;
                      vertical-align: middle;
                    }
                    .changeWallpaperBtn {
                      padding: 4px 26px;
                      float: right;
                      margin-top: 4px;
                    }
                      .dropdownBtn {
                      padding: 4px 26px;
                      float: right;
                      // margin-top: 4px;
                      margin-bottom: 10px;
                    }
                    .editBtn {
                      padding: 4px 26px;
                      float: right;
                      margin-top: 4px;
                    }
                    .resetBtn {
                      padding: 4px 26px;
                      float: right;
                      margin-top: 4px;
                    }
                  }
                }
              }
            }
          }
          .deviceWorkspaceWrapper {
            background: $white;
            .deviceWorkspaceDetails {
              padding: 24px 16px;
              padding-bottom: 32px;
              border-bottom: 1px solid $gray10;
              .workspaceDetailsHeading {
                .workspaceDetailsTitle {
                  color: $gray50;
                  font-weight: $mediumFont;
                  margin-bottom: 24px;
                }
              }
              .workspaceDetailsRow {
                margin-bottom: 24px;
                &:last-child {
                  margin-bottom: 0;
                }

                .workspaceDetailsLeftBlk {
                  .workspaceDetailsText {
                    font-weight: $regularFont;
                    line-height: $lineHeight21;
                    color: $gray50;
                  }
                }
                .workspaceDetailsRightBlk {
                  .workspaceIconWrapper {
                    position: relative;
                    & img {
                      width: 14px;
                      height: 14px;
                      object-fit: cover;
                      position: absolute;
                      left: 0;
                      top: 4px;
                    }
                  }
                  .workspaceDetailsRightText {
                    margin-left: 22px;
                    color: $black;
                  }
                  .workspaceDetailsAdminList {
                    ul {
                      padding: 0;
                      li {
                        padding: 0;
                        position: relative;
                        margin-bottom: 8px;
                        & img {
                          width: 14px;
                          height: 14px;
                          object-fit: cover;
                          position: absolute;
                          left: 0;
                          top: 4px;
                        }
                      }
                      .workspaceDetailsRightText {
                        margin-left: 22px;
                        color: $black;
                      }
                    }
                  }
                }
              }
            }
          }
          .deviceRoomDetailsWrapper {
            background: $white;
            .deviceRoomDetails {
              padding: 24px 16px;
              .roomDetailsHeading {
                .roomDetailsTitle {
                  color: $gray50;
                  font-weight: $mediumFont;
                  margin-bottom: 24px;
                  display: inline-block;
                }
                .editBtn {
                  position: relative;
                  top: -4px;
                  float: right;
                  padding: 4px 20px;
                }
              }
              .roomDetailsRow {
                margin-bottom: 24px;
                &:last-child {
                  margin-bottom: 0;
                }
                .roomDetailsLeftBlk {
                  .roomDetailsText {
                    font-weight: $regularFont;
                    line-height: $lineHeight21;
                    color: $gray50;
                  }
                }
                .roomDetailsRightBlk {
                  .roomIconWrapper {
                    position: relative;
                    & img {
                      width: 14px;
                      height: 14px;
                      object-fit: cover;
                      position: absolute;
                      left: 0;
                      top: 4px;
                    }
                  }
                  .roomDetailsRightText {
                    margin-left: 22px;
                    color: $black;
                  }
                  .roomDetailsSeatingCapacity {
                    color: $black;
                  }
                  .roomDetailsDeviceList {
                    ul {
                      padding: 0;
                      li {
                        padding: 0;
                        position: relative;
                        margin-bottom: 8px;
                        & img {
                          width: 14px;
                          height: 14px;
                          object-fit: cover;
                          position: absolute;
                          left: 0;
                          top: 4px;
                        }
                      }
                      .workspaceDetailsRightText {
                        margin-left: 22px;
                        color: $black;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
