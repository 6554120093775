// @import "variable";
// body {
//   .ordersPage {
//     // background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
//     padding: 114px 0px 0px 240px;
//     .ordersListWrapper {
//       .orderHeading {
//         border-bottom: 1px solid $gray10;
//         .orderTitle {
//           color: $gray100;
//           padding: 16px 24px;
//         }
//       }
//       .ordersListStatus {
//         .orderFilter {
//           display: flex;
//           padding: 0;
//           margin: 0 24px;
//           border-bottom: 1px solid $gray10;
//           .filterItem {
//             width: auto;
//             padding: 16px;
//             font-weight: $mediumFont;
//             text-transform: capitalize;
//             cursor: pointer;
//             border-bottom: 2px solid $white;
//           }
//           & :global(.Mui-selected) {
//             border-bottom: 2px solid $blue;
//           }
//           & :global(.MuiTabs-indicator) {
//             display: none;
//           }
//         }
//       }
//       .orderSearchBlk {
//         padding: 16px 24px;
//         .orderSearchWrapper {
//           display: flex;
//           gap: 16px;
//           justify-content: flex-end;
//           .orderSearchInput {
//             position: relative;
//             width: 160px;
//             & input {
//               background: $white;
//               border: none;
//               height: 32px;
//               padding: 14px 8px;
//               font-size: $font12;
//             }
//             & :global(.inputGroup) {
//               margin-bottom: 0;
//             }
//             & img {
//               object-fit: cover;
//               position: absolute;
//               right: 6px;
//               top: 7px;
//             }
//           }
//           .orderFilterInput {
//             display: inline-block;
//             width: 120px;
//             & :global(.inputGroup) {
//               margin-bottom: 0;
//             }
//             // & :global(.reactSelect > div) {
//             //     border: none;
//             //     height: 32px;
//             //     min-height: 32px;
//             // }
//             & :global(.css-zr4xlc-placeholder) {
//               font-size: 12px;
//             }
//             & :global(.css-zv9he5-IndicatorsContainer) {
//               padding: 0 4px;
//               height: 32px;
//             }
//             & :global(.css-8u5oqz-ValueContainer) {
//               padding: 0 4px;
//               height: 32px;
//             }
//           }
//         }
//       }
//       .ordersList {
//         padding: 0px 24px 24px;
//         .ordersListInnerBlk {
//           background: $white;
//           box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
//           border-radius: 6px;
//           .ordersListTableTitle {
//             padding: 16px 16px;
//             border-bottom: 1px solid $gray10;
//             .ordersTableTitleTextWrapper {
//               display: flex;
//               justify-content: flex-end;
//               &:first-child {
//                 justify-content: flex-start;
//               }
//               .ordersTableTitleText {
//                 font-size: $font12;
//                 color: $gray50;
//               }
//               & img {
//                 padding-left: 4px;
//                 cursor: pointer;
//               }
//             }
//           }
//           .ordersListTableContent {
//             .orderListItem {
//               border-bottom: 1px solid $gray10;
//               display: block;
//               &:last-child {
//                 border-bottom: none;
//               }
//               .ordersListTableRow {
//                 padding: 16px;
//                 .orderContentWrapper {
//                   display: flex;
//                   .orderImageWrapper {
//                     position: relative;
//                     .orderBox {
//                       height: 40px;
//                       width: 40px;
//                       object-fit: cover;
//                     }
//                     .orderBoxStatus {
//                       position: absolute;
//                       right: 0;
//                       bottom: 0;
//                     }
//                   }
//                   .orderDetailWrapper {
//                     padding-left: 12px;
//                     display: flex;
//                     flex-direction: column;
//                     justify-content: center;
//                     .quoteNum {
//                       font-weight: $mediumFont;
//                     }
//                     .companyName {
//                       color: $gray90;
//                       font-weight: $regularFont;
//                     }
//                   }
//                   .requestedOn {
//                     color: $gray90;
//                     word-break: break-word;
//                   }
//                   .orderStatus {
//                     color: $status_orange;
//                     font-weight: $mediumFont;
//                     word-break: break-word;
//                   }
//                   .unitsCount {
//                     color: $primaryBlue;
//                     font-weight: $mediumFont;

//                     word-break: break-word;
//                   }
//                   .subscriptionPeriod {
//                     color: $gray90;
//                     word-break: break-word;
//                     & span {
//                       font-weight: $mediumFont;
//                     }
//                   }
//                   .priceStatus {
//                     border-radius: 4px;
//                     font-weight: $mediumFont;
//                     padding: 0px 6px;
//                     display: inline-block;
//                     &.success {
//                       color: $color_success_text;
//                       background: $status_green;
//                     }
//                     &.info {
//                       color: $powder;
//                       background: $status_blue;
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   .orderDrawerDetailWrapper {
//     width: 960px;
//     background-color: $white;
//     .orderDetailTitle {
//       background-color: $background9;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding: 16px 24px;
//       .orderDetailHeader {
//         color: $gray90;
//       }
//       & img {
//         cursor: pointer;
//       }
//     }
//     .orderRightDrawer {
//       .orderDetailWrapper {
//         background-color: $background4;
//         border-radius: 8px;
//         margin: 24px 16px;
//         .orderDetailBlk {
//           padding: 16px;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           .orderLeftBlk {
//             display: flex;
//             align-items: center;
//             .orderImageWrapper {
//               position: relative;
//               .orderBox {
//                 padding: 8px;
//                 border-radius: 4px;
//                 border: 1px solid #e6e8e9;
//               }
//               .orderBoxStatus {
//                 position: absolute;
//                 bottom: 0;
//                 right: 0;
//               }
//             }
//             .quoteNumber {
//               padding-left: 12px;
//             }
//           }
//           .orderRightBlk {
//             .userDetailDownloadLink {
//               color: $blue;
//               font-weight: $mediumFont;
//               display: flex;
//               align-items: center;
//               & img {
//                 padding-right: 8px;
//               }
//             }
//           }
//         }
//         .orderDetailTitles {
//           font-size: $font12;
//           color: $gray50;
//         }
//         .orderDetailDescriptions {
//           font-size: $font12;
//           font-weight: $mediumFont;
//           color: $gray100;
//           word-break: break-word;
//         }
//       }
//       .paymentDetailWrapper {
//         border-radius: 8px;
//         margin: 24px 16px;
//         border: 1px solid $gray10;
//         .paymentDetailHeader {
//           padding: 16px;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           .paymentDetailTitle {
//             color: $gray90;
//             .paymentDetailDate {
//               padding-left: 16px;
//               color: $gray2;
//               font-size: $font10;
//             }
//           }
//         }
//         .paymentCardWrapper {
//           display: flex;
//           align-items: center;
//           padding: 0 16px 16px;
//           .paymentCardStatus {
//             margin-right: 160px;
//             display: flex;
//             align-items: center;
//             & img {
//               padding: 5px;
//               border-radius: 4px;
//               border: 1px solid $gray10;
//             }
//             .cardStatus {
//               color: $color_info_text;
//               font-weight: $mediumFont;
//               padding-left: 8px;
//             }
//           }
//           .paymentDueWrapper {
//             color: $gray2;
//             .paymentDueDate {
//               padding-left: 24px;
//               color: $gray100;
//             }
//           }
//         }
//         .paymentAdminUpdate {
//           padding: 0 16px;
//           display: flex;
//           gap: 16px;
//           .selectStatus {
//             & :global(.css-myyece-control) {
//               width: 172px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $white;
//               & :hover {
//                 background-color: $background8;
//               }
//               border-radius: 4px;
//               & :active {
//                 width: 172px;
//                 height: 40px;
//                 border: 1px solid $blue;
//               }
//             }
//             & :global(.css-4n0wjs-control) {
//               width: 172px;
//               height: 40px;
//               border: 1px solid $blue;
//               background-color: $background1;
//               border-radius: 4px;
//             }
//             & :global(.css-8u5oqz-ValueContainer) {
//               height: 40px;
//               display: flex;
//               align-items: center;
//             }
//             & :global(.css-zv9he5-IndicatorsContainer) {
//               height: 40px;
//             }
//             & :global(.css-zr4xlc-placeholder) {
//               padding-top: 12px;
//             }
//             & :global(.css-b8ldur-Input) {
//               height: 40px;
//             }
//           }
//           .selectDate {
//             & :global(.MuiInput-root) {
//               width: 160px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $background8;
//               border-radius: 4px;
//             }
//           }
//         }
//       }
//       .shipmentDetailWrapper {
//         border-radius: 8px;
//         margin: 24px 16px;
//         border: 1px solid $gray10;
//         .shipmentDetailHeader {
//           padding: 16px;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           .shipmentDetailTitle {
//             color: $gray100;
//             .shipmentDetailDate {
//               padding-left: 16px;
//               color: $gray2;
//               font-size: $font10;
//             }
//           }
//           .newShipmentButton {
//             position: relative;
//             padding: 5px 10px 5px 28px;
//             border-radius: 4px;
//             & img {
//               position: absolute;
//               top: 8px;
//               left: 8px;
//             }
//           }
//         }
//         .shipmentUserSection {
//           border-radius: 8px;
//           margin: 16px;
//           border: 1px solid $gray10;
//           .shipmentImageWrapper {
//             padding-left: 16px;
//             position: relative;
//             .shipmentBox {
//               padding: 8px;
//               border-radius: 4px;
//               width: 100%;
//               height: 100%;
//               object-fit: contain;
//               border: 1px solid #e6e8e9;
//             }
//             .shipmentBoxStatus {
//               position: absolute;
//               bottom: 4px;
//               right: 0;
//             }
//           }
//           .shipmentBlkHeader {
//             color: $gray50;
//             font-size: $font12;
//           }
//           .shipmentBlkDescription {
//             color: $gray100;
//             font-weight: $mediumFont;
//           }
//           .shipmentBlkStatus {
//             font-weight: $mediumFont;
//             color: $powder;
//             border-radius: 4px;
//             background-color: $status_blue;
//           }
//           .trackButton {
//             padding: 8px 10px;
//             border-radius: 8px;
//             position: relative;
//             left: 12px;
//           }
//         }
//         .shipmentAdminDetailList {
//           display: flex;
//           justify-content: space-between;
//           align-items: flex-start;
//           margin: 0 16px;
//           padding: 24px 0;
//           border-bottom: 1px solid $gray10;
//           &:last-child {
//             border-bottom: none;
//           }
//           & :global(.inputGroup) {
//             margin: 0;
//           }
//           .shipmentNumberWrapper {
//             .shipmentNumberTitle {
//               color: $gray50;
//               font-weight: $regularFont;
//             }
//             .shipmentNumber {
//               padding-top: 12px;
//               color: $gray90;
//               font-weight: $mediumFont;
//             }
//           }
//           .selectShipmentStatus {
//             & :global(.css-myyece-control) {
//               width: 172px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $white;
//               & :hover {
//                 background-color: $background8;
//               }
//               border-radius: 4px;
//               & :active {
//                 width: 172px;
//                 height: 40px;
//                 border: 1px solid $blue;
//               }
//             }
//             & :global(.css-4n0wjs-control) {
//               width: 172px;
//               height: 40px;
//               border: 1px solid $blue;
//               background-color: $background1;
//               border-radius: 4px;
//             }
//             & :global(.css-8u5oqz-ValueContainer) {
//               height: 40px;
//               display: flex;
//               align-items: center;
//             }
//             & :global(.css-zv9he5-IndicatorsContainer) {
//               height: 40px;
//             }
//             & :global(.css-zr4xlc-placeholder) {
//               padding-top: 12px;
//             }
//             & :global(.css-b8ldur-Input) {
//               height: 40px;
//             }
//           }
//           .selectShipmentNumberStatus {
//             & :global(.css-myyece-control) {
//               width: 90px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $white;
//               & :hover {
//                 background-color: $background8;
//               }
//               border-radius: 4px;
//               & :active {
//                 width: 90px;
//                 height: 40px;
//                 border: 1px solid $blue;
//               }
//             }
//             & :global(.css-4n0wjs-control) {
//               width: 90px;
//               height: 40px;
//               border: 1px solid $blue;
//               background-color: $background1;
//               border-radius: 4px;
//             }
//             & :global(.css-8u5oqz-ValueContainer) {
//               height: 40px;
//               display: flex;
//               align-items: center;
//             }
//             & :global(.css-zv9he5-IndicatorsContainer) {
//               height: 40px;
//             }
//             & :global(.css-zr4xlc-placeholder) {
//               padding-top: 12px;
//             }
//             & :global(.css-b8ldur-Input) {
//               height: 40px;
//             }
//           }
//           .selectDeliveryDate {
//             & :global(.MuiInput-root) {
//               width: 160px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $background8;
//               border-radius: 4px;
//             }
//           }
//           .shipmentTrackLink {
//             & :global(.MuiInput-root) {
//               width: 220px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $background8;
//               border-radius: 4px;
//             }
//             & :global(input) {
//               width: 220px;
//               height: 40px;
//               border: 1px solid $gray10;
//               background-color: $background8;
//               border-radius: 4px;
//             }
//           }
//         }
//       }
//     }
//     .updateButtonWrapper {
//       position: sticky;
//       padding: 12px 16px;
//       bottom: 0;
//       width: 100%;
//       text-align: right;
//       background-color: $background8;
//       border-top: 1px solid $gray10;
//       .updateBtn {
//         padding: 14px 72px;
//       }
//     }
//   }
// }

@import "variable";
body {
  .ordersPage {
    // background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
    padding: 114px 0px 0px 240px;
    .ordersListWrapper {
      .orderHeading {
        border-bottom: 1px solid $gray10;
        .orderTitle {
          color: $gray100;
          padding: 16px 24px;
          .orderTitleNum {
            color: $gray90;
            font-size: $font12;
          }
        }
      }
      .ordersListStatus {
        .orderFilter {
          display: flex;
          padding: 0;
          margin: 0 24px;
          border-bottom: 1px solid $gray10;
          .filterItem {
            width: auto;
            padding: 16px;
            font-weight: $mediumFont;
            text-transform: capitalize;
            cursor: pointer;
            border-bottom: 2px solid $white;
          }
          & :global(.Mui-selected) {
            border-bottom: 2px solid $blue;
          }
          & :global(.MuiTabs-indicator) {
            display: none;
          }
        }
      }
      .orderSearchBlk {
        padding: 16px 24px;
        .orderSearchWrapper {
          display: flex;
          gap: 16px;
          justify-content: flex-end;
          .orderSearchInput {
            position: relative;
            width: 160px;
            & input {
              background: $white;
              border: none;
              height: 32px;
              padding: 14px 8px;
              font-size: $font12;
            }
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            & img {
              object-fit: cover;
              position: absolute;
              right: 6px;
              top: 7px;
            }
          }
          .orderFilterInput {
            display: inline-block;
            width: 120px;
            & :global(.inputGroup) {
              margin-bottom: 0;
            }
            // & :global(.reactSelect > div) {
            //     border: none;
            //     height: 32px;
            //     min-height: 32px;
            // }
            & :global(.css-zr4xlc-placeholder) {
              font-size: 12px;
            }
            & :global(.css-zv9he5-IndicatorsContainer) {
              padding: 0 4px;
              height: 32px;
            }
            & :global(.css-8u5oqz-ValueContainer) {
              padding: 0 4px;
              height: 32px;
            }
          }
        }
      }
      .ordersList {
        padding: 0px 24px 24px;
        .ordersListInnerBlk {
          background: $white;
          box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
          border-radius: 6px;
          .ordersListTableTitle {
            padding: 16px 16px;
            border-bottom: 1px solid $gray10;
            .ordersTableTitleTextWrapper {
              display: flex;
              justify-content: flex-end;
              &:first-child {
                justify-content: flex-start;
              }
              .ordersTableTitleText {
                font-size: $font12;
                color: $gray50;
              }
              & img {
                padding-left: 4px;
                cursor: pointer;
              }
            }
          }
          .ordersListTableContent {
            .orderListItem {
              border-bottom: 1px solid $gray10;
              display: block;
              opacity: 0.9;
              transition: all 0.6s ease-in-out;
              &:hover {
                opacity: 1;
                box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
              }
              &:last-child {
                //border-bottom: none;
              }
              .ordersListTableRow {
                padding: 10px;
                .orderContentWrapper {
                  display: flex;
                  .orderImageWrapper {
                    position: relative;
                    .orderBox {
                      height: 48px;
                      width: 48px;
                      object-fit: cover;
                    }
                    .orderBoxStatus {
                      position: absolute;
                      right: 1px;
                      bottom: 6px;
                    }
                  }
                  .orderDetailWrapper {
                    padding-left: 12px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .quoteNum {
                      font-weight: $mediumFont;
                    }
                    .companyName {
                      color: $gray90;
                      font-weight: $regularFont;
                    }
                  }
                  .requestedOn {
                    color: $gray90;
                    word-break: break-word;
                  }
                  .orderStatus {
                    color: $status_orange;
                    font-weight: $mediumFont;
                    word-break: break-word;
                  }
                  .orderStatusPartillayPaid {
                    color: $color_info_text;
                    font-weight: $mediumFont;
                    word-break: break-word;
                  }
                  .orderStatusPaid {
                    color: $color_success_text;
                    font-weight: $mediumFont;
                    word-break: break-word;
                  }
                  .unitsCount {
                    color: $primaryBlue;
                    font-weight: $mediumFont;

                    word-break: break-word;
                  }
                  .subscriptionPeriod {
                    color: $gray90;
                    word-break: break-word;
                    & span {
                      font-weight: $mediumFont;
                    }
                  }
                  .priceStatus {
                    border-radius: 4px;
                    font-weight: $mediumFont;
                    padding: 0px 6px;
                    display: inline-block;
                    &.success {
                      color: $color_success_text;
                      background: $status_green;
                    }
                    &.info {
                      color: $powder;
                      background: $status_blue;
                    }
                  }
                  & img {
                    padding: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .orderDrawerDetailWrapper {
    width: 960px;
    background-color: $white;
    .orderDetailTitle {
      background-color: $background9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      .orderDetailHeader {
        color: $gray90;
      }
      & img {
        cursor: pointer;
      }
    }
    .orderRightDrawer {
      .orderDetailWrapper {
        background-color: $background4;
        border-radius: 8px;
        margin: 24px 16px;
        .orderDetailBlk {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .orderLeftBlk {
            display: flex;
            align-items: center;
            .orderImageWrapper {
              position: relative;
              .orderBox {
                padding: 8px;
                border-radius: 4px;
                border: 1px solid #e6e8e9;
              }
              .orderBoxStatus {
                position: absolute;
                bottom: 18px;
                right: 8px;
              }
            }
            .quoteNumber {
              padding-left: 12px;
            }
          }
          .orderRightBlk {
            .userDetailDownloadLink {
              color: $blue;
              font-weight: $mediumFont;
              display: flex;
              align-items: center;
              & img {
                padding-right: 8px;
              }
            }
          }
        }
        .orderDetailTitles {
          font-size: $font12;
          color: $gray50;
        }
        .orderDetailDescriptions {
          font-size: $font12;
          font-weight: $mediumFont;
          color: $gray100;
          word-break: break-word;
        }
      }
      .paymentDetailWrapper {
        border-radius: 8px;
        margin: 24px 16px;
        border: 1px solid $gray10;
        .paymentDetailHeader {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .paymentDetailTitle {
            color: $gray90;
            .paymentDetailDate {
              padding-left: 16px;
              color: $gray2;
              font-size: $font10;
            }
          }
        }
        .paymentCardWrapper {
          display: flex;
          align-items: center;
          padding: 0 16px 16px;
          .paymentCardStatus {
            margin-right: 160px;
            display: flex;
            align-items: center;
            & img {
              padding: 5px;
              border-radius: 4px;
              border: 1px solid $gray10;
            }
            .cardStatusPending {
              color: $color_warning_text;
              font-weight: $mediumFont;
              padding-left: 8px;
            }
            .cardStatusPaid {
              color: $color_success_text;
              font-weight: $mediumFont;
              padding-left: 8px;
            }
            .cardStatusPartiallyPaid {
              color: $color_info_text;
              font-weight: $mediumFont;
              padding-left: 8px;
            }
          }
          .paymentDueWrapper {
            color: $gray2;
            .paymentDueDate {
              padding-left: 24px;
              color: $gray100;
            }
          }
        }
        .paymentAdminUpdate {
          padding: 0 16px;
          display: flex;
          gap: 16px;
          .selectStatus {
            width: 160px;
            & :global(.css-myyece-control) {
              width: 172px;
              height: 38px;
              border: 1px solid $gray10;
              background-color: $background8;
              & :hover {
                background-color: $background8;
              }
              border-radius: 4px;
              & :active {
                width: 172px;
                height: 40px;
                border: 1px solid $blue;
              }
            }
            & :global(.css-4n0wjs-control) {
              width: 172px;
              height: 40px;
              border: 1px solid $blue;
              background-color: $background8;
              border-radius: 4px;
            }
            & :global(.css-8u5oqz-ValueContainer) {
              display: flex;
              align-items: center;
              position: relative;
              background-color: $background8;
              border-radius: 4px;
            }
            & :global(.css-acimh-singleValue) {
              position: absolute;
              top: 60% !important;
            }
            & :global(.css-zv9he5-IndicatorsContainer) {
              height: 38px;
              position: relative;
              background-color: $background8;
              border-radius: 4px;
            }

            & :global(.css-b8ldur-Input) {
              height: 38px;
            }
          }
          .selectDate {
            & :global(.MuiInput-root) {
              width: 160px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
            }
          }
        }
      }
      .shipmentDetailWrapper {
        border-radius: 8px;
        margin: 24px 16px;
        border: 1px solid $gray10;
        .shipmentDetailHeader {
          padding: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .shipmentDetailTitle {
            color: $gray100;
            .shipmentDetailDate {
              padding-left: 16px;
              color: $gray2;
              font-size: $font10;
            }
          }
          .newShipmentButton {
            position: relative;
            padding: 5px 10px 5px 28px;
            border-radius: 4px;
            & img {
              position: absolute;
              top: 8px;
              left: 8px;
            }
          }
        }
        .shipmentUserSection {
          border-radius: 8px;
          margin: 16px;
          border: 1px solid $gray10;
          .shipmentImageWrapper {
            padding-left: 16px;
            position: relative;
            .shipmentBox {
              padding: 8px;
              border-radius: 4px;
              width: 100%;
              height: 100%;
              object-fit: contain;
              border: 1px solid #e6e8e9;
            }
            .shipmentBoxStatus {
              position: absolute;
              bottom: 4px;
              right: 0;
            }
          }
          .shipmentBlkHeader {
            color: $gray50;
            font-size: $font12;
          }
          .shipmentBlkDescription {
            color: $gray100;
            font-weight: $mediumFont;
          }
          .shipmentBlkStatusPending {
            font-weight: $mediumFont;
            color: $powder;
            border-radius: 4px;
            background-color: $powder2;
          }
          .shipmentBlkStatusReceived {
            font-weight: $mediumFont;
            color: $color_success_text;
            border-radius: 4px;
            background-color: $status_green;
          }
          .shipmentBlkStatusShipped {
            font-weight: $mediumFont;
            color: $color_warning_text;
            border-radius: 4px;
            background-color: $color_warning_bg;
          }
          .trackButton {
            padding: 8px 10px;
            border-radius: 8px;
            position: relative;
            left: 12px;
          }
        }
        .shipmentAdminDetailList {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin: 0 16px;
          padding: 24px 0;
          border-bottom: 1px solid $gray10;
          &:last-child {
            border-bottom: none;
          }
          & :global(.inputGroup) {
            margin: 0;
          }
          .shipmentNumberWrapper {
            .shipmentNumberTitle {
              color: $gray50;
              font-weight: $regularFont;
            }
            .shipmentNumber {
              padding-top: 12px;
              color: $gray90;
              font-weight: $mediumFont;
            }
          }
          .selectShipmentStatus {
            & :global(.css-myyece-control) {
              width: 172px !important;
              height: 40px !important;
              border: 1px solid $gray10;
              background-color: $background8;
              & :active {
                width: 172px !important;
                height: 40px !important;
                border: 1px solid $background8;
                border-radius: 4px;
              }
            }
            & :global(.css-4n0wjs-control) {
              width: 172px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
            }
            & :global(.css-8u5oqz-ValueContainer) {
              // height: 39px !important;
              display: flex;
              align-items: center;
              position: relative;
              width: 140px;
              border-radius: 4px;
              background-color: $background8;
            }
            & :global(.css-acimh-singleValue) {
              position: absolute;
              // top: 60% !important;
              background-color: $background8;
            }
            & :global(.css-zv9he5-IndicatorsContainer) {
              height: 38px;
              background-color: $background8;
              border-radius: 4px;
            }
            & :global(.css-b8ldur-Input) {
              height: 38px;
            }
          }

          .shipmentNumStatus {
            & :global(.MuiInput-root) {
              width: 120px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
            }
            & :global(input) {
              width: 120px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
            }
          }
          .selectDeliveryDate {
            & :global(.MuiInput-root) {
              width: 160px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
            }
          }
          .shipmentTrackLink {
            & :global(.MuiInput-root) {
              width: 220px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
              color: $blue !important;
              cursor: pointer;
            }
            & :global(input) {
              width: 220px;
              height: 40px;
              border: 1px solid $gray10;
              background-color: $background8;
              border-radius: 4px;
              color: $blue !important;
              cursor: pointer;
            }
          }
          .removeUsersBtn {
            position: relative;
            height: 20px;
            top: 35px;
            cursor: pointer;
          }
        }
      }
    }
    .updateButtonWrapper {
      position: sticky;
      padding: 12px 16px;
      bottom: 0;
      width: 100%;
      text-align: right;
      background-color: $background8;
      border-top: 1px solid $gray10;
      .updateBtn {
        padding: 14px 72px;
      }
    }
  }
  .ordersHomePage {
    padding: 60px 0px 0px 240px;
  }
}
