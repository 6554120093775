@import "variable";
body {
  .teamPage {
    padding-top: 56px;
    margin: 0px 80px;
    .teamWrapper {
      .backBtnWrapper {
        padding: 32px 0px;
      }
      .teamHeaderWrapper {
        .teamPageTitleWrapper {
          padding: 16px 0px;
          border-bottom: 1px solid $gray10;
          .teamPageTitle {
            color: $primaryBlue;
          }
        }
      }
      .teamSearchBlk {
        padding: 16px 0;
        margin: 0 24px;
        .teamSearchInput {
          position: relative;
          text-align: right;
          width: 160px;
          margin-left: 90px;
          & input {
            background: $white;
            border: none;
            height: 32px;
            border-radius: 4px;
            padding: 14px 8px;
            font-size: $font12;
          }
          & :global(.inputGroup) {
            margin-bottom: 0;
          }
          & img {
            object-fit: cover;
            position: absolute;
            right: 6px;
            top: 7px;
          }
        }
        .addNewTeamMember {
          text-align: right;
          .addButton {
            position: relative;
            padding: 5px 12px 5px 34px;
            border-radius: 4px;
            & img {
              position: absolute;
              top: 8px;
              left: 12px;
            }
          }
        }
      }
      .teamListBlk {
        .teamList {
          padding: 0px 24px 24px;
          .teamListInnerBlk {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;
            .teamListTableTitle {
              padding: 16px 16px;
              border-bottom: 1px solid $gray10;
              .teamTableTitleTextWrapper {
                display: flex;
                justify-content: flex-end;
                &:first-child {
                  justify-content: flex-start;
                }
                .teamTableTitleText {
                  font-size: $font12;
                  color: $gray50;
                }
                & img {
                  padding-left: 4px;
                }
              }
            }
            .teamListTableContent {
              .teamListItem {
                border-bottom: 1px solid $gray10 !important;
                display: block;
                cursor: pointer;
                &:last-child {
                  border-bottom: none;
                }
                @include transitionForAll($time: 0.3s, $transProp: ease-in);
                &:hover {
                  box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
                  .teamEditOption {
                    display: block !important;
                  }
                }
                .teamListTableRow {
                  padding: 16px;
                  position: relative;
                  .teamContentWrapper {
                    display: flex;
                    .teamImageWrapper {
                      position: relative;
                      .teamBox {
                        padding: 8px;
                        width: 50px;
                        height: 50px;
                      }
                    }
                    .teamDetailWrapper {
                      padding-left: 12px;
                      display: flex;
                      flex-direction: column;
                      // align-items: center;
                      .teamName {
                        font-weight: $mediumFont;
                      }
                      .teamEmail {
                        color: $gray50;
                        font-weight: $regularFont;
                      }
                    }
                    .lastActive,
                    .permission {
                      color: $gray90;
                      padding-top: 8px;
                      font-weight: $mediumFont;
                      word-break: break-word;
                      & span {
                        padding-left: 4px;
                        color: $blue;
                      }
                    }
                    .joinedOn {
                      color: $gray50;
                      font-weight: $regularFont;
                      padding-top: 8px;
                      word-break: break-word;
                      & span {
                        padding-left: 4px;
                        color: $blue;
                      }
                    }
                  }
                  .teamEditOption {
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: none;
                    @include transitionForAll($time: 0.3s, $transProp: ease-in);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .userDialogWrapper {
    .userDialogTitle {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      border-bottom: 1px solid $gray10;
      .userDialogHeader {
        color: $gray100;
      }
    }
    .userDialogDetail {
      padding: 24px 0 0 0;
      margin: 0 32px;
      border-bottom: 1px solid $gray10;
      .userDialogInput {
        display: flex;
        .userInputEmail {
          & input {
            width: 300px;
            border-radius: 8px 0 0 8px;
          }
        }
        .userInputOrg {
          & :global(.css-myyece-control) {
            width: 200px;
            height: 48px;
            border: 1px solid $gray10;
            background-color: $white;
            border-radius: 0 8px 8px 0;
            & :active {
              width: 200px;
              height: 48px;
              border: 1px solid $blue;
            }
          }
          & :global(.css-4n0wjs-control) {
            width: 200px;
            height: 48px;
            border: 1px solid $blue;
            background-color: $background1;
            border-radius: 0 8px 8px 0;
          }
          width: 200px;
          position: relative;
          top: -1px;
          /* & :global(.css-4n0wjs-control){
                        width: 200px;
                        border: 1px solid #343334;
                    }
                    & :global(.css-myyece-control){
                        height: 48px;
                        border-radius: 0 8px 8px 0;
                        background-color: $white;
                    } */
        }
      }
      .userDialogDropdown {
        width: 500px;
        & :global(.css-26l3qy-menu) {
          width: 300px;
          border-radius: 4px;
          & input {
            border-radius: 4px;
          }
          > div {
            border-radius: 4px;
          }
        }
      }
    }
    .removeUsersBtn {
      position: relative;
      height: 16px;
      left: 10px;
      top: 15px;
      cursor: pointer;
    }
    .userDialogButtonWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 36px;
      .userButtonAddMore {
        color: $blue;
        font-weight: $mediumFont;
        position: relative;
        margin: 16px 0 48px 0;
        & img {
          position: absolute;
          top: 4px;
          left: -24px;
        }
      }
      .inviteBtn {
        width: 115px;
        border-radius: 4px;
      }
    }
  }
}
