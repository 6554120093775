@import "variable";

body {
  .partnerSignin {
    background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/signin_bg.svg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    .LogoWrapper {
      & img {
        object-fit: cover;
        position: absolute;
        top: 16px;
        left: 24px;
      }
    }
    .signInLeftHeading {
      margin-left: 140px;
      .leftSubHeading {
        font-weight: $mediumFont;
      }
    }
    .signinFormWrapper {
      background-color: $white;
      padding: 40px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      border-radius: 16px;
      .passwordSection {
        position: relative;
        margin-top: 12px;
        .forgotPasswordBlk {
          position: absolute;
          top: 0;
          right: 0;
          color: $blue;
        }
      }
      .signInButton {
        width: 100%;
        padding: 14px 0;
        margin-top: 20px;
      }
      .signupForm {
        background-color: red;
        .socialAuth {
          padding: 24px 16px 0px 16px !important;
        }
        .signupSeparatorWrapper {
          .signupSeparator {
            &::after,
            &::before {
              width: 50px !important;
            }
          }
        }
        .signinFormInputWrapper {
          padding: 0px 16px 24px 16px !important;
        }
      }
    }
  }
  .forgotPassword {
    background: url(https://letsving-web.s3.ap-south-1.amazonaws.com/letsving/assets/img/signin_bg.svg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    .LogoWrapper {
      & img {
        object-fit: cover;
        position: absolute;
        top: 16px;
        left: 24px;
      }
    }
    .forgotPasswordWrapper {
      .forgotPasswordLeftHeading {
        margin: 0 48px 0 140px;
        .leftSubHeading {
          font-weight: $regularFont;
        }
      }
      .forgotPasswordFormWrapper {
        background-color: $white;
        padding: 48px 40px 24px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        .signInButton {
          width: 100%;
          padding: 14px 0;
          margin: 12px 0 72px;
        }
        .backButtonWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gray50;
          .backButtonLink > span {
            margin-left: 6px;
            color: $blue;
          }
        }
      }
      .successSection {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .successWrapper {
          background-color: $white;
          border-radius: 8px;
          text-align: center;
          .imageWrapper {
            background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
            padding: 60px 0 12px 0;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
          }
          .contentWrapper {
            padding: 0 40px;
            .contentHeading {
              line-height: $lineHeight36;
            }
            .contentPara {
              padding: 24px 0 16px;
              color: $gray50;
              & span {
                font-weight: $mediumFont;
              }
            }
            .contentSubPara {
              padding-bottom: 40px;
              color: $gray50;
            }
            .contentBtn {
              width: 100%;
              padding: 14px 0;
            }
          }
          .backButtonWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $gray50;
            padding: 40px 0 32px;
            .backButtonLink > span {
              margin-left: 6px;
              color: $blue;
            }
          }
        }
      }
    }
    .resetPasswordWrapper {
      .resetPasswordLeftHeading {
        margin: 0 10px 0 140px;
        .leftSubHeading {
          font-weight: $regularFont;
        }
      }
      .resetPasswordFormWrapper {
        background-color: $white;
        padding: 40px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 16px;
        .resetPasswordEmailWrapper {
          display: flex;
          justify-content: center;
          .resetPasswordEmail {
            padding-bottom: 40px;
            color: $gray50;
            & span {
              padding-left: 6px;
              color: $gray80;
            }
          }
        }
        .passwordInputField {
          margin-bottom: 12px;
        }
        .passwordTabInput {
          & :global(.inputGroup) {
            margin-bottom: 26px;
          }
          position: relative;
          .showPassword {
            position: absolute;
            top: 39px;
            right: 14px;
            z-index: 99;
            cursor: pointer;
            text-transform: uppercase;
          }
        }
        .signInButton {
          width: 100%;
          padding: 14px 0;
        }
      }
      .resetPasswordSuccess {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .successWrapper {
          background-color: $white;
          border-radius: 8px;
          text-align: center;
          .imageWrapper {
            background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
            padding: 40px 120px;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
          }
          .contentWrapper {
            padding: 0 40px;
            .contentHeading {
              line-height: $lineHeight36;
            }
            .contentPara {
              margin: 32px 0 48px;
              color: $gray50;
              & span {
                font-weight: $mediumFont;
              }
            }
          }
          .contentBtn {
            width: 100%;
            padding: 14px 0;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
  .signUpPage {
    background-color: #fff;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    .LogoWrapper {
      & img {
        width: 46px;
        object-fit: cover;
        position: absolute;
        top: 16px;
        left: 50px;
      }
    }
  }
  .successSection {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .successWrapper {
      background-color: $white;
      border-radius: 8px;
      text-align: center;
      .imageWrapper {
        background: linear-gradient(180deg, #f7fbf9 0%, #ecf3f8 100%);
        padding: 60px 0 12px 0;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
      .contentWrapper {
        padding: 32px 40px 40px;
        .contentHeading {
          line-height: $lineHeight36;
        }
        .contentPara {
          padding: 16px 0 50px 0;
          color: $gray50;
        }
        .contentBtn {
          width: 100%;
        }
      }
    }
  }
}
