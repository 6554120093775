@import "variable";
body {
  .allOrganisationPage {
    padding-top: 56px;
    margin: 0px 80px;
    .allOrganisationWrapper {
      .backBtnWrapper {
        padding: 32px 0px;
      }
      .allOrganisationHeaderWrapper {
        .allOrganisationPageTitleWrapper {
          padding: 16px 0px;
          border-bottom: 1px solid $gray10;
          .allOrganisationPageTitle {
            color: $primaryBlue;
          }
        }
      }
      .organisationSearchBlk {
        padding: 16px 0;
        margin: 0 24px;
        .organisationSearchInput {
          position: relative;
          text-align: right;
          width: 160px;
          margin-left: 90px;
          & input {
            background: $white;
            border: none;
            height: 32px;
            border-radius: 4px;
            padding: 14px 8px;
            font-size: $font12;
          }
          & :global(.inputGroup) {
            margin-bottom: 0;
          }
          & img {
            object-fit: cover;
            position: absolute;
            right: 6px;
            top: 7px;
          }
        }
        .addNewOrganisation {
          text-align: right;
          .addButton {
            position: relative;
            padding: 5px 12px 5px 34px;
            border-radius: 4px;
            & img {
              position: absolute;
              top: 8px;
              left: 12px;
            }
          }
        }
      }
      .allOrganisationListBlk {
        .allOrganisationList {
          padding: 0px 24px 24px;
          .allOrganisationListInnerBlk {
            background: $white;
            box-shadow: 0px 2px 2px rgba(103, 115, 125, 0.17);
            border-radius: 6px;
            .allOrganisationListTableTitle {
              padding: 16px 16px;
              border-bottom: 1px solid $gray10;
              .allOrganisationTableTitleTextWrapper {
                display: flex;
                justify-content: flex-end;
                &:first-child {
                  justify-content: flex-start;
                }
                .allOrganisationTableTitleText {
                  font-size: $font12;
                  color: $gray50;
                }
                & img {
                  padding-left: 4px;
                }
              }
            }
            .allOrganisationListTableContent {
              .allOrganisationListItem {
                border-bottom: 1px solid $gray10 !important;
                display: block;
                cursor: pointer;
                &:last-child {
                  border-bottom: none;
                }
                @include transitionForAll($time: 0.3s, $transProp: ease-in);
                &:hover {
                  box-shadow: 0px 0px 10px rgba(77, 92, 104, 0.15);
                  .organisationEditOption {
                    display: block !important;
                  }
                }
                .allOrganisationListTableRow {
                  padding: 16px;
                  position: relative;
                  .allOrganisationContentWrapper {
                    display: flex;
                    .allOrganisationImageWrapper {
                      position: relative;
                      .allOrganisationBox {
                        padding: 8px;
                      }
                    }
                    .allOrganisationDetailWrapper {
                      padding-left: 12px;
                      display: flex;
                      align-items: center;
                      .allOrganisationName {
                        font-weight: $mediumFont;
                      }
                    }
                    .noOfWorkspace,
                    .noOfBox,
                    .adminEmail {
                      color: $gray90;
                      padding-top: 8px;
                      word-break: break-word;
                      & span {
                        padding-left: 4px;
                        color: $blue;
                      }
                    }
                  }
                  .organisationEditOption {
                    position: absolute;
                    right: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                    display: none;
                    @include transitionForAll($time: 0.3s, $transProp: ease-in);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
