//mobile portrait view //
@media (min-width: 0px) and (max-width: 599px) {
  body {
    .squareRadioButton {
      display: inline-flex !important;
      margin-right: 8px;
      margin-bottom: 8px;
      &.largeButton {
        width: 100px;
        height: 72px;
      }
    }
    .heading6 {
      font-size: $font18;
    }
    .xs-block {
      display: block !important;
    }

    .heroSection {
      .heading {
        padding-top: 60px !important;
      }
    }
    .getSection {
      padding: 220px 0 265px 0 !important;
    }
  }
}