@import "variable";

body{
    .legalHeading{
        color: $gray100 !important;
        margin: 60px 0 20px;
    }
    .legalOverview{
        color: $gray90 !important;
        line-height: $lineHeight36;  
    }
    .legalPrivacy{
        color: $gray90 !important;
        line-height: $lineHeight21;
        font-size: $font16 !important;
        margin-bottom: 20px;
    }
    .termTitles{
        color: $gray80 !important;
        margin-bottom: 12px;
    }
    .subTitle{
        font-size: $font16;
        margin-bottom: 8px;
        color: $gray80 ;
    }
    .termText{
        color: $gray100 !important;
        margin-bottom: 24px;
        text-align: justify;
    }
    .legalLink{
        color: $blue2;
    }
    .companyAddress{
        color: $gray100 !important;
        font-weight: $semiBoldFont;
        margin-bottom: 60px;
    }
    .effectiveDate{
        display: block;
        padding: 12px 0 0;
        font-weight: $semiBoldFont;
        font-size: $font18;
    }
   
}